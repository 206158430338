import { toast } from "react-toastify";
import moment from "moment";
import { createContext } from "react";
import CryptoJS from "crypto-js";
import { jsPDF } from "jspdf";

import { font } from "../resources/fonts/fontBase64";
import ValidationLibrary from "./validationfunction";

export const dropdownNameWithEmail = (data) => {
  return `${data?.first_name} ${data?.last_name} - ${data?.email}`;
};
export const dropdownName = (data) => {
  return `${data?.first_name} ${data?.last_name}`;
};
export const NotificationContext = createContext({
  notifications: false,
  setNotifications: () => {},
});

export const Toaster = {
  warn: function (value) {
    toast.dismiss();
    toast.warn(value);
  },
  error: function (value) {
    toast.clearWaitingQueue("1");
    toast.dismiss();
    // if value is html content
    if (value?.includes("<div>")) {
      toast.error(<div dangerouslySetInnerHTML={{ __html: value }} />);
    }
    //toast.dismiss();
    else
      toast.error(value, {
        toastId: "1",
      });
    //toast.dismiss();
  },
  success: function (value) {
    toast.dismiss();
    toast.success(value);
  },
  info: function (value) {
    toast.dismiss();
    toast.info(value);
  },
  warning: function (value) {
    toast.dismiss();
    toast.warn(value);
  },
  remove: function () {
    toast.dismiss();
  },
};
export const dateFormatter = (date, current) => {
  if (current) return moment().format("MMM DD yyyy");

  return date && date !== "" ? moment(date).format("MMM DD yyyy") : "";
};

export const dateTimeFormatter = (date) => {
  return date && date !== "" ? moment(date).format("MMM DD yyyy hh:mm") : "";
};

export const encryptPassword = (password) => {
  return CryptoJS.AES.encrypt(password, "finlotax").toString();
};

export const decryptPassword = (password) => {
  // Decrypt
  var bytes = CryptoJS.AES.decrypt(password, "finlotax");
  return bytes.toString(CryptoJS.enc.Utf8);
};

export const getNodeText = (node, joinValue) => {
  if (["string", "number"].includes(typeof node)) return node;
  if (node instanceof Array) return node.map(getNodeText).join(joinValue || "");
  if (typeof node === "object" && node) return getNodeText(node.props.children);
};

export const Loader = (isShow) => {
  // eslint-disable-next-line no-unused-expressions
  isShow
    ? document.getElementById("showLoader") !== null
      ? document.getElementById("showLoader").click()
      : false
    : document.getElementById("hideLoader") !== null
    ? document.getElementById("hideLoader").click()
    : false;
};

export function wait(milliseconds) {
  return new Promise((resolve) => setTimeout(resolve, milliseconds));
}

export const detailsBind = (keys, values, Arr) => {
  let details = {};
  keys.forEach((data, index) => {
    details[data] = {
      value: values[index] === 0 || values[index] ? Arr[values[index]] : "",
    };
  });
  return details;
};
export const addressFormator = (...value) => {
  const inputValues = [...value];
  var outputValues = "";
  inputValues.map((data, index) => {
    if (data && data !== "null") {
      if (index === 0) {
        outputValues = outputValues.concat(data);
      } else {
        outputValues = outputValues.concat(", ", data);
      }
    }
    return true;
  });
  return outputValues;
};

export const getUserTypeID = () => {
  const roles = [
    {
      id: 1,
      value: "Admin",
    },
    {
      id: 2,
      value: "Manager",
    },
    {
      id: 3,
      value: "Employee",
    },
    {
      id: 4,
      value: "Client",
    },
  ];
  const user_role = localStorage.getItem("user_role");
  let findData = roles.find(
    (data) => data.value.toLowerCase() === user_role
  ).id;
  return findData;
};

export const getDocumentBlob = async () => {
  const input = document.getElementById("divToPrint");

  return new Promise((resolve) => {
    const framebox = document.getElementsByClassName("silver-fieldset");
    for (var i = 0; i < framebox.length; i++) {
      framebox[i].classList.add("pdf-framebox");
    }
    const doc = new jsPDF({ unit: "px", format: "letter", userUnit: "px" });
    let docWidth = doc.internal.pageSize.getWidth();

    doc.addFileToVFS("Nunito-Regular.ttf", font);
    doc.addFont("Nunito-Regular.ttf", "Nunito Regular", "normal");
    doc.setFont("Nunito Regular");
    doc
      .html(input, {
        callback: function (doc) {
          resolve(doc.output("blob"));

          // doc.save("file.pdf");
        },

        autoPaging: "text",
        margin: [0, 0, 0, 0],
        width: docWidth,
        windowWidth: 1000,
      })
      .then(() => {
        for (var i = 0; i < framebox.length; i++) {
          framebox[i].classList.remove("pdf-framebox");
        }
      });
  });
};



export function checkValidation({ key, value, state, setState }) {
  const errorcheck = ValidationLibrary.checkValidation(
    value,
    state[key].validation
  );
  const dynObj = {
    ...state[key],
    value,
    error: !errorcheck.state,
    errmsg: errorcheck.msg,
  };

  setState((prevState) => ({
    ...prevState,
    [key]: dynObj,
  }));
}
export function cancelValidation({ setState, defaultState }) {
  setState(defaultState);
}
export function onSubmitValidation({ state, setState, checkFields }) {
  let filtererr = [];
  const fields = checkFields?.length > 0 ? checkFields : state;
  for (const keys in fields) {
    const key = checkFields?.length > 0 ? fields[keys] : keys;
    const errorcheck = ValidationLibrary.checkValidation(
      state[key].value,
      state[key].validation
    );
    state[key].error = !errorcheck.state;
    state[key].errmsg = errorcheck.msg;
    if (state[key].error) filtererr.push(key);
  }
  setState((prevState) => ({
    ...prevState,
  }));
  return filtererr;
}
