import API from "../../utils/api";
import { createAsyncThunk } from "@reduxjs/toolkit";
import TokenService from "../../utils/token-service";

const assignedBy = (params) => {
  return TokenService.getUserRole() === "employee"
    ? { ...params, billed_by: TokenService.getUserID() }
    : params;
};

const insertUpdateContract = async (state) => {
  try {
    const { params, insertUpdateFlag, client_contract_id } = state;
console.log("the params are ", params)
    const API_NAME =
      insertUpdateFlag === 0
        ? "contract/createContract"
        : `contract/updateContractById/${client_contract_id}`;
    const data = await API.post(`${API_NAME}`, params);
    return data;
  } catch (error) {
    return [];
  }
};

const updateProposal = async (params) => {
  try {
    console.log("update proposal is triggred!!")
    const data = await API.post(`contract/updateProposal`, params);
    return data;
  } catch (error) {
    return [];
  }
};

const sendEmail = async (params) => {
  try {
    const data = await API.post(
      `${
        params.origin === "Contract" ? "contract" : "invoice"
      }/sendAttachment/${params.id}${params.create ? "?create=true" : ""}`,
      params.binaryData
    );
    return data;
  } catch (error) {
    return [];
  }
};

// const sendEmail = async (params) => {
//   try {
//     // Determine the endpoint based on the action (acceptance or rejection)
//     const action = params.isRejection ? "rejectNotification" : "sendAttachment";
//     const data = await API.post(
//       `${
//         params.origin === "Contract" ? "contract" : "invoice"
//       }/${action}/${params.id}${params.create ? "?create=true" : ""}`,
//       params.binaryData
//     );
//     return data;
//   } catch (error) {
//     console.error("Error sending email:", error);
//     return { status: 0, message: "Failed to send the email." }; // Return more informative feedback
//   }
// };

const getAllContracts = createAsyncThunk(
  "contract/getAllContractsStatus",
  async (params = {}) => {
    try {
      const {
        data: { data },
      } = await API.post(`contract/getAllContract`, assignedBy(params));
      return data;
    } catch (error) {
      console.log(error);
      return [];
    }
  }
);

const getContractDetail = async (contract_id) => {
  try {
    const data = await API.get(`contract/getContractById/${contract_id}`);
    return data;
  } catch (error) {
    console.log(error);
    return [];
  }
};


const deleteContractById = async (contract_id) => {
  try {
    const data = await API.delete(`contract/deleteContractById/${contract_id}`);
    return data;
  } catch (error) {
    console.log(error);
    return [];
  }
};
export {
  deleteContractById,
  insertUpdateContract,
  getAllContracts,
  updateProposal,
  sendEmail,
  getContractDetail,
};
