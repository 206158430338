import React from "react";
import { Grid, Paper } from "@material-ui/core";
import { Labelbox, Table, FrameBox } from "..";
import { dateFormatter } from "../../utils/common";
import "./timesheet.css";

export default function TimesheetView({ data, id }) {
  return (
    <Grid
      container
      // direction="row"
      // justifyContent="center"
      alignItems="center"
      spacing={2}
      id={id}
      style={{ padding: 15 }}
    >
      <Grid container spacing={3} className="contract-padding">
        <Grid item xs={12} sm={3} md={3} lg={3}>
          <Labelbox
            type="label"
            value={dateFormatter(data.start_date)}
            label="Start Date"
          />
        </Grid>
        <Grid item xs={12} sm={3} md={3} lg={3}>
          <Labelbox
            type="label"
            value={dateFormatter(data.end_date)}
            label="End Date"
          />
        </Grid>
        <Grid item xs={12} sm={3} md={3} lg={3}>
          <Labelbox
            type="label"
            value={data.employee_name}
            label="Employee Name"
          />
        </Grid>
        <Grid item xs={12} sm={3} md={3} lg={3}>
          <Labelbox type="label" value={data.total_hours} label="Total hours" />
        </Grid>
      </Grid>

      {data.rejected_comments ? (
        <Grid container spacing={3} className="paddingTop">
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FrameBox title={`Rejected Comments`} variant="small">
              <Paper>{data.rejected_comments}</Paper>
            </FrameBox>
          </Grid>
        </Grid>
      ) : (
        <></>
      )}
      <Grid container spacing={3} className="paddingTop">
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Table
            variant="small"
            title="Items"
            header={[
              { id: "1", label: "Date" },
              { id: "2", label: "Client" },
              { id: "3", label: "Business Name" },
              { id: "4", label: "Activity" },
              { id: "5", label: "Sub Activity" },
              { id: "6", label: "Description" },
              { id: "7", label: "Hours" },
            ]}
            data={data?.itemDetails.map(
              ({
                date,
                client_name,
                business_name,
                activity,
                sub_activity,
                hours,
                description,
              }) => {
                return {
                  date: dateFormatter(date),
                  client_name,
                  business_name,
                  activity,
                  sub_activity,
                  description,
                  hours,
                };
              }
            )}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
