import React, { useEffect, useState, useMemo } from "react";
import { Labelbox, Table, FrameBox, InfoTable, Button } from "../../components";
import { Grid, Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";
import { CLIENT_REFERRAL_ACTION } from "../../redux/action";
import ValidationLibrary from "../../utils/validationfunction";
import { Toaster, dateTimeFormatter } from "../../utils/common";

const useStyes = makeStyles((theme) => ({
  margin: {
    [theme.breakpoints.up("md")]: {
      marginTop: "1rem",
    },
  },
}));

const ReferralProgram = () => {
  const classes = useStyes();
  const dispatch = useDispatch();
  const defaultCreateState = {
    referral_name: {
      labelname: "Name",
      value: "",
      validation: [{ name: "required" }],
      error: null,
      errmsg: null,
    },
    referral_email: {
      labelname: "Email Address",
      value: "",
      validation: [{ name: "required" }, { name: "emailNew" }],
      error: null,
      errmsg: null,
    },
    referral_phone: {
      labelname: "Phone",
      value: "",
      validation: [{ name: "required" }, { name: "mobile" }],
      error: null,
      errmsg: null,
    },
  };


  const USER_DATA = JSON.parse(localStorage.getItem("USER_DATA"));

  const [fieldConfiguration, setFieldConfiguration] =
    useState(defaultCreateState);
  const { getAllReferrals } = useSelector((state) => state.referral);

  const referralsTableData = useMemo(() => {
    return getAllReferrals.map((data) => {
      const {
        referral_name,
        referral_email,
        referral_phone,
        bonus,
        // status,
        createdAt,
      } = data;
      return {
        referral_name,
        referral_email,
        referral_phone,
        bonus,
        // status: status ? "Redeemed " : "Pending",
        createdAt: dateTimeFormatter(createdAt),
      };
    });
  }, [getAllReferrals]);

  useEffect(() => {
    dispatch(CLIENT_REFERRAL_ACTION.getAllReferral());
  }, []);

  const handleChange = (value, fieldKey) => {
    const errorcheck = ValidationLibrary.checkValidation(
      value,
      fieldConfiguration[fieldKey].validation
    );
    fieldConfiguration[fieldKey].value = value;
    fieldConfiguration[fieldKey].error = !errorcheck.state;
    fieldConfiguration[fieldKey].errmsg = errorcheck.msg;
    setFieldConfiguration({ ...fieldConfiguration });
  };

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      const errorList = Object.keys(fieldConfiguration).filter((fieldKey) => {
        const errorcheck = ValidationLibrary.checkValidation(
          fieldConfiguration[fieldKey].value,
          fieldConfiguration[fieldKey].validation
        );
        fieldConfiguration[fieldKey].error = !errorcheck.state;
        fieldConfiguration[fieldKey].errmsg = errorcheck.msg;
        return !errorcheck.state;
      });
      setFieldConfiguration({ ...fieldConfiguration });
      if (!errorList.length) {
        const status = await CLIENT_REFERRAL_ACTION.insertClientReferral({
          client_id: localStorage.getItem("user_id"),
          referral_name: fieldConfiguration.referral_name.value,
          referral_email: fieldConfiguration.referral_email.value,
          referral_phone: fieldConfiguration.referral_phone.value,
        });
        if (status === "Success") {
          dispatch(CLIENT_REFERRAL_ACTION.getAllReferral());
          setFieldConfiguration(defaultCreateState);
          Toaster.success("Referral added successfully");
        } else Toaster.error(status);
      }
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <>
      <FrameBox title="Referral Program">
        <Grid container spacing={3} className="paddingTop">
          {localStorage.getItem("user_role") === "client" && (
            <>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Paper className="payment-notes">
                  <span>
                    Refer a friend and earn a $25 credit on your bill. Your
                    friend gets a $25 discount too on all services automatically
                  </span>
                </Paper>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <FrameBox title="Tax Associate Info" variant="small">
                  <Paper>
                    <InfoTable
                      titles={["Name", "Email ID", "Phone"]}
                      info={{
                        name: USER_DATA.employee || "-",
                        email: USER_DATA.employee_email || "-",
                        phone: USER_DATA.employee_phone || "",
                      }}
                    />
                  </Paper>
                </FrameBox>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} className="support-info">
                <FrameBox title="Support Info" variant="small">
                  <Paper>
                    <InfoTable
                      titles={["Email ID", "Phone"]}
                      info={{
                        email: "help@finlotax.com",
                        contact: "(408) 822-9406",
                      }}
                    />
                  </Paper>
                </FrameBox>
              </Grid>
              <Grid
                container
                spacing={3}
                alignItems="center"
                className="contract-padding"
              >
                {Object.keys(fieldConfiguration).map((data, index) => (
                  <Grid item xs={12} md={4} sm={6} lg={3} key={`data${index}`}>
                    <Labelbox
                      type={fieldConfiguration[data].type || "text"}
                      labelname={fieldConfiguration[data].labelname}
                      changeData={(e) => handleChange(e, data)}
                      disabled={fieldConfiguration[data].disabled}
                      value={fieldConfiguration[data].value}
                      error={fieldConfiguration[data].error}
                      errmsg={fieldConfiguration[data].errmsg}
                    />
                  </Grid>
                ))}

                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={3}
                  lg={3}
                  className={classes.margin}
                >
                  <Button text="Submit" large handleClick={handleSubmit} />
                </Grid>
              </Grid>
            </>
          )}
          <Grid item xs={12}>
            <Table
              variant="small"
              title="Programs Info"
              header={[
                { id: "1", label: "Name" },
                { id: "2", label: "Email ID" },
                { id: "3", label: "Phone" },
                { id: "4", label: "Bonus" },
                // { id: "5", label: "Status" },
                { id: "6", label: "Created on" },
              ]}
              data={referralsTableData}
            />
          </Grid>
        </Grid>
      </FrameBox>
    </>
  );
};

export default ReferralProgram;
