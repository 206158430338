import API from "../../utils/api";

const googleSignUp = async (params) => {
  try {
    const API_NAME = "client/googleSignUp/";
    const data = await API.get(`${API_NAME}${params}`);
    return data;
  } catch (error) {
    return [];
  }
};

export { googleSignUp };
