import documentReducer from "./documentReducer";
import clientReducer from "./clientReducer";
import contractReducer from "./contractReducer";
import invoiceReducer from "./invoiceReducer";
import employeeReducer from "./employeeReducer";
import masterReducer from "./masterReducer";
import clientReferralReducer from "./clientReferralReducer";
import timesheetReducer from "./timesheetReducer";
import { combineReducers } from "@reduxjs/toolkit";

const reducer = combineReducers({
  document: documentReducer,
  client: clientReducer,
  employee: employeeReducer,
  master: masterReducer,
  contract: contractReducer,
  invoice: invoiceReducer,
  referral: clientReferralReducer,
  timesheet: timesheetReducer,
});

const reducerProxy = (state, action) => {
  if (action.type === "resetReducers") {
    return reducer(undefined, action);
  }
  return reducer(state, action);
};

export default reducerProxy;
