import { createSlice } from "@reduxjs/toolkit";
import { getAllReferral } from "../action/clientReferralAction";

const initialState = {
  getAllReferrals: [],
};

export const employeeSlice = createSlice({
  name: "referral",
  initialState,
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder.addCase(getAllReferral.fulfilled, (state, action) => {
      // Add user to the state array
      state.getAllReferrals = action.payload;
    });
  },
});

export default employeeSlice.reducer;
