import React, {
  useRef,
  createRef,
  useState,
  useLayoutEffect,
  useEffect,
  useMemo,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Labelbox,
  Table,
  FrameBox,
  InfoTable,
  Button,
  Modal,
  ContractInvoiceView,
  Spinner,
} from "../../components";
import { Grid, Paper } from "@material-ui/core";
import "./contract_proposal.scss";
import { CONTRACT_ACTION } from "../../redux/action";
import { Toaster, dateFormatter, getDocumentBlob } from "../../utils/common";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";

const ContractProposal = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [approvedData, setApprovedData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [proposals, setProposals] = useState([]);
  const [isModalOpen, setModalOpen] = useState({ state: false, data: {} });
  const submitValidateRef = createRef();
  const cancelValidateRef = createRef();
  const componentRef = useRef();
  const location = useLocation();
  const { userId } = props;
  const USER_DATA = userId
    ? props.USER_DATA
    : JSON.parse(localStorage.getItem("USER_DATA"));

  useMemo(() => {
    if (location.state?.data)
      setModalOpen({ state: true, data: location.state.data });
  }, [location]);

  const onChange = (value, key, rowIndex) => {
    proposals[rowIndex][key] = value;
    setProposals([...proposals]);
  };

  const onActionClick = (action, row, actionIndex) => {
    setModalOpen({ state: true, data: allContracts[actionIndex] });
  };

  const fields = [
    {
      type: "checkbox",
      changeData: onChange,
      toggleDisableCheck: true,
    },
    {
      type: "label",
    },
    {
      type: "label",
    },
    {
      type: "label",
    },
    {
      type: "select",
      validation: [{ name: "required" }],
      error: null,
      errmsg: null,
      dropdown: [
        { id: 1, value: "Approved" },
        { id: 2, value: "Declined" },
      ],
      changeData: onChange,
      disabled: true,
    },
    {
      type: "text",
      validation: [
        { name: "required" },
        { name: "custommaxLength", params: "255" },
      ],
      error: null,
      errmsg: null,
      changeData: onChange,
      disabled: true,
    },
  ];

  useLayoutEffect(() => {
    dispatch(
      CONTRACT_ACTION.getAllContracts({
        billed_to: userId || localStorage.getItem("user_id"),
        status: 0,
      })
    );
  }, [userId, dispatch]);

  const {
    contract: { allContracts },
  } = useSelector((state) => state);

  useEffect(() => {
    setProposals(
      allContracts.map((data) => {
        const { contract_no, contract_date, total } = data;
        return {
          select: false,
          contract_no,
          contract_date: dateFormatter(contract_date),
          amount: `$ ${total}`,
          actions: "",
          client_comment: "",
        };
      })
    );
  }, [allContracts]);

  async function onSubmit() {
    const params = [];
    proposals.forEach(({ actions, client_comment, select }, index) => {
      select === true &&
        params.push({
          billed_to: userId || localStorage.getItem("user_id"),
          client_contract_id: allContracts[index].client_contract_id,
          status: actions,
          client_comment,
        });
    });
    if (params.length > 0) {
      const checkValidity = submitValidateRef.current;
      const filterError = checkValidity();
      if (filterError) {
        Toaster.error("Please fill up required fields");
        return;
      }
      const response = await CONTRACT_ACTION.updateProposal(params);
      
      dispatch(
        CONTRACT_ACTION.getAllContracts({
          billed_to: userId || localStorage.getItem("user_id"),
          status: 0,
        })
      );

      if (response.status === 200 && response.data.status === 1) {
        console.log("response data", response.data)
        if (response.data.data.length) {
      
          setLoading(true);
          setApprovedData(response.data.data[0]);
          setTimeout(handleSendMail, 2000, response.data.data[0]);
        } else {
          console.log("the contract proposal is declined!!!")
          // await handleSendRejectionMail(params);
          //emp details -> create decline mail endpoint (api) -> mail employee!!
          Toaster.success(`Contract updated successfully`);
          !userId && navigate("/finlo/payment");
          // Add the mail method so that corresponding employee shoud receives an email.
        
        }
      } else if (response.status === 200 && response.data.status === 0) {
        
        Toaster.warning(response.data.message);
      } else {
        Toaster.error(`Error occurred while processing the proposal`);
      }
    } else Toaster.warning("Select at least one item");
  }
  // const handleCancel = () => {
  //   const cancel = cancelValidateRef.current;
  //   cancel();
  // };

  async function handleSendRejectionMail(contractParams) {
    console.log("Rejection email is triggered");
  
    try {
      const response = await CONTRACT_ACTION.sendEmail({
        isRejection: true, // Indicates that this is a rejection email
        create: false, // No need to create, just notify the rejection
        origin: "Invoice",
        id: contractParams[0].client_contract_id, // Assuming you want to send a single rejection email for the first declined contract
      });
  
      if (response.status === 200 && response.data.status === 1) {
        Toaster.success(`Rejection notification has been successfully sent to the employee.`);
      } else if (response.status === 200 && response.data.status === 0) {
        Toaster.warning(response.data.message);
      } else {
        Toaster.error(`Error occurred while sending the rejection notification.`);
      }
    } catch (error) {
      Toaster.error(`An unexpected error occurred while sending the rejection notification.`);
    }
  }
  


  async function handleSendMail(invoiceData) {
    console.log("email is triggred")
    let formData = new FormData();
    formData.append("file", await getDocumentBlob());

    const response = await CONTRACT_ACTION.sendEmail({
      binaryData: formData,
      create: true,
      origin: "Invoice",
      id: invoiceData.client_invoice_id,
      isRejection: false, // Set to true if it's a rejection
  
    });
    setLoading(false);
    if (response.status === 200 && response.data.status === 1) {
      // Toaster.success(`Attachment has been successfully sent to the client `);
      Toaster.success(`Contract updated successfully`);
      !userId && navigate("/finlo/payment");
    } else if (response.status === 200 && response.data.status === 0) {
      Toaster.warning(response.data.message);
    } else {
      Toaster.error(`Error occurred while sending the attachment`);
    }
  }

  const View = ({ id }) => (
    <ContractInvoiceView
      inputRef={componentRef}
      id={id || false}
      data={approvedData}
      label={"Invoice"}
    />
  );

  return (
    <FrameBox title="Contract Proposal / Engagement">
      <div style={{ display: "none" }}>
        {Object.keys(approvedData).length > 0 && <View id="divToPrint" />}
      </div>
      <Grid container spacing={3} className="paddingTop">
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <FrameBox title="Tax Associate Info" variant="small">
            <Paper>
              <InfoTable
                titles={["Name", "Email ID", "Phone"]}
                info={{
                  name: USER_DATA.employee || "-",
                  email: USER_DATA.employee_email || "-",
                  phone: USER_DATA.employee_phone || "",
                }}
              />
            </Paper>
          </FrameBox>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} className="support-info">
          <FrameBox title="Support Info" variant="small">
            <Paper>
              <InfoTable
                titles={["Email ID", "Phone"]}
                info={{
                  email: "help@finlotax.com",
                  contact: "(408) 822-9406",
                }}
              />
            </Paper>
          </FrameBox>
        </Grid>
        <Grid item xs={12}>
          <Table
            variant="small"
            title="Proposals"
            header={[
              { id: "1", label: "" },
              { id: "2", label: "" },
              { id: "3", label: "Description / Contract Proposal" },
              { id: "4", label: "Contract Date" },
              { id: "5", label: "Amount" },
              { id: "6", label: "Actions" },
              { id: "7", label: "Comments" },
            ]}
            data={proposals}
            fields={fields}
            validateRef={submitValidateRef}
            cancelValidate={cancelValidateRef}
            icons={{ eyeIcon: true }}
            onActionClick={onActionClick}
            toggleDisable
          />
        </Grid>
        <Grid item xs={12}>
          <Labelbox
            type="checkbox"
            label={
              <span className="terms">
                I have read the{" "}
                <span>Terms & Conditions, Privacy Policy, Disclaimer</span> and
                approve this contract proposal for processing
              </span>
            }
            checked
          />
        </Grid>
        <Grid item xs={12} spacing={12} className="flex flex-center">
          <Button text="Submit" large handleClick={onSubmit} />
        </Grid>
      </Grid>
      {isModalOpen.state && (
        <Modal
          open={isModalOpen.state}
          title="Contract Preview"
          maxWidth
          handleClose={() => setModalOpen({ state: false, data: {} })}
        >
          <ContractInvoiceView data={isModalOpen.data} label="Contract" />
        </Modal>
      )}
      {loading && <Spinner />}
    </FrameBox>
  );
};

export default ContractProposal;
