import React, { useState, useEffect } from "react";
import { useSearchParams, useNavigate , useLocation} from "react-router-dom";
import { Labelbox } from "../../components";
//import ValidationLibrary from "../../utils/validationfunction";
import { AUTH_ACTION } from "../../redux/action";
import { Toaster } from "../../utils/common";

const ResetPassword = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const [token, setToken] = useState('');
  const [email, setEmail] = useState('');
  // const urlqueryParams = new URLSearchParams(location.search);
  //   const email = urlqueryParams.get('email');
  const tempPassword = searchParams.get("tempPassword");
 //console.log("Password Reset for email: ",email);
  const [inputs, setInputs] = useState({
    password: {
      type: "text",
      labelname: "Password",
      value: "",
      validation: [
        { name: "required" },
        { name: "minLength", value: 8 },
        { name: "pattern", regex: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_]).{8,}$/, errorMsg: "Password must include at least one uppercase letter, one lowercase letter, one number, and one special character." },
      ],
      error: null,
      errmsg: null,
      password: true,
    },
    confirmPassword: {
      type: "text",
      labelname: "Confirm Password",
      value: "",
      validation: [
        { name: "required" },
        { name: "comparePassword", compareText: "" },
      ],
      error: null,
      errmsg: null,
      password: true,
    },
  });

  // useEffect(() => {
  //   if (!email || !tempPassword) {
  //     Toaster.error("Invalid or missing reset link parameters.");
  //   }
  // }, [email, tempPassword]);

  

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get('token');
    const email = queryParams.get('email');
    setToken(token);
    setEmail(email);
  }, [location]);


  const handleChange = (value, fieldKey) => {
    if (fieldKey === "confirmPassword")
      inputs[fieldKey].validation[1].compareText = inputs.password.value;

    const errorcheck = checkValidation(
      value,
      inputs[fieldKey].validation
    );
    inputs[fieldKey].value = value;
    inputs[fieldKey].error = !errorcheck.state;
    inputs[fieldKey].errmsg = errorcheck.msg;
    setInputs({ ...inputs });
  };

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      const errorList = Object.keys(inputs).filter((fieldKey) => {
        const errorcheck = checkValidation(
          inputs[fieldKey].value,
          inputs[fieldKey].validation
        );
        inputs[fieldKey].error = !errorcheck.state;
        inputs[fieldKey].errmsg = errorcheck.msg;
        return !errorcheck.state;
      });
  
      setInputs({ ...inputs });
      if (!errorList.length) {
    //     const urlqueryParams = new URLSearchParams(location.search);
    // const email = urlqueryParams.get('email');
    console.log("## The Email to reset password is: ",email);
        const status = await AUTH_ACTION.authService(
          {
            email: email,
            tempPassword,
            newPassword: inputs.password.value,
            token:token
          },
          "resetAuth"
        );
        if (status === "Success") {
          Toaster.success("Password has been reset successfully");
          navigate("/");
        } else Toaster.error(status);
      }
    } catch (error) {
      console.error(error);
      Toaster.error("An error occurred while resetting the password.");
    }
  };
  
  const checkValidation = (value, rules) => {
    for (let rule of rules) {
      if (rule.name === "required" && !value.trim()) {
        return { state: false, msg: "This field is required" };
      }
      if (rule.name === "minLength" && value.length < rule.value) {
        return { state: false, msg: `Minimum ${rule.value} characters required` };
      }
      if (rule.name === "pattern" && !rule.regex.test(value)) {
        return { state: false, msg: rule.errorMsg };
      }
      if (
        rule.name === "comparePassword" &&
        value !== rule.compareText
      ) {
        return { state: false, msg: "Passwords do not match" };
      }
    }
    return { state: true, msg: "" };
  };

  return (
    <div>
      <div
        className="signup_container align_column"
        style={{ height: "100vh" }}
      >
        <div
          className="signup_block align_column credential_card"
          style={{ height: "auto" }}
        >
          <div className="signup_info credential_title">
            <h2 className="welcome">Reset Password</h2>
          </div>
          <form action="" className="signup-form input-warpper">
            <div className="input_block">
              {Object.keys(inputs)?.map((fieldKey, index) => (
                <Labelbox
                  key={index}
                  type={inputs[fieldKey].type}
                  labelname={inputs[fieldKey].labelname}
                  value={inputs[fieldKey].value}
                  changeData={(value) => handleChange(value, fieldKey)}
                  error={inputs[fieldKey].error}
                  errmsg={inputs[fieldKey].errmsg}
                  password={inputs[fieldKey].password}
                />
              ))}
            </div>
            <center>
              <button
                className="signin_button action_btn"
                onClick={handleSubmit}
              >
                Reset
              </button>
            </center>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
