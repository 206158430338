import { createSlice } from "@reduxjs/toolkit";
import { getUserFiles, getUserFolders } from "../action/documentAction";

const initialState = {
  userFiles: [],
  userFolders: [],
};

export const documentSlice = createSlice({
  name: "document",
  initialState,
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder.addCase(getUserFiles.fulfilled, (state, action) => {
      // Add user to the state array
      state.userFiles = action.payload;
    });
    builder.addCase(getUserFolders.fulfilled, (state, action) => {
      // Add user to the state array
      state.userFolders = action.payload;
    });
  },
});

// Action creators are generated for each case reducer function
// export const { getUserFiles } = documentSlice

export default documentSlice.reducer;
