import React, { useState } from "react";
import "./spinner.css";
import CircularProgress from "@material-ui/core/CircularProgress";

const AppLoader = () => {
  const [isLoaderVisible, setIsLoaderVisible] = useState(false);
  return (
    <>
      {isLoaderVisible && (
        <div className="spinner-wrapper">
          <div className="spinner-ball">
            <CircularProgress />
          </div>
        </div>
      )}
      <input
        aria-label="loaderButton"
        type="button"
        id="showLoader"
        onClick={(e) => {
          setIsLoaderVisible(true);
        }}
        style={{ display: "none" }}
      />
      <input
        aria-label="loaderButton"
        type="button"
        id="hideLoader"
        onClick={(e) => {
          setIsLoaderVisible(false);
        }}
        style={{ display: "none" }}
      />
    </>
  );
};

export default AppLoader;
