import React, { useState, useEffect } from "react";
import "./login.css";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import { Spinner } from "../../components";
import { API_URL } from "../../utils/baseUrl";
import { Toaster, encryptPassword } from "../../utils/common";
import { USER_ACTION } from "../../redux/action";
import { Link } from "react-router-dom";
import { FaEye, FaEyeSlash } from "react-icons/fa";

const LoginPage = () => {
  const [isLoaded, setLoader] = useState(false);
  const location = useLocation();
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false); // State to toggle password visibility

  const query = new URLSearchParams(useLocation().search);
  useEffect(() => {
    if (query.get("googleAuth")) {
      setLoader(true);
      googleAuth(query.get("googleAuth"));
    }
  }, []);
  const [values, setValues] = React.useState({
    showPassword: false,
});
const togglePasswordVisibility = () => {
  setShowPassword(!showPassword);
};
  const [inputs, setInputs] = useState({
    email: "",
    password: "",
    code: "",
  });
  const [emailError, setEmailError] = useState({
    state: false,
    msg: "",
  });
  const [error, setError] = useState({
    state: false,
    msg: "",
  });
  const [passwordError, setPasswordError] = useState({
    state: false,
    msg: "",
  });
  const [codeError, setCodeError] = useState({
    state: false,
    msg: "",
  });
  const [verifyOTPState, setVerifyOTP] = useState(false);
  const navigate = useNavigate();
  
  const handleChange = (e) => {
    setInputs((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    const { name, value } = e.target;
    if (name === "password") setPassword(value);
  };
  // const google = () => {
  //   window.open("http://localhost:4000/auth/google", "_self");
  // };
  
  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }
  
  const handleSubmit = async (e, verifyOTPState) => {
    e.preventDefault();
    try {
      if (!verifyOTPState) {
        if (inputs.email.length === 0) {
          setEmailError({
            name: "Please enter your email",
            state: true,
          });
        } else if (!isValidEmail(inputs.email)) {
          setEmailError({
            name: "Invalid Email",
            state: true,
          });
        } else {
          setEmailError({
            name: "",
            state: false,
          });
        }
        if (inputs.password.length === 0) {
          setPasswordError({
            name: "Please enter your password",
            state: true,
          });
        } else {
          setPasswordError({
            name: "",
            state: false,
          });
        }
        if (emailError.state === false && passwordError.state === false) {
          // encrypt
          const processedPassword = encryptPassword(inputs.password);
          setLoader(true);
          const output = await axios.post(`${API_URL}auth/login`, {
            email: inputs.email,
            password: processedPassword,
          });
          setLoader(false);
          if (output.status === 200 && output.data.status === 1) {
            Toaster.success(output.data.message);
            setVerifyOTP(true);
          } else {
            Toaster.error(output.data.message);
          }
        }
      } else {
        if (inputs.code.length === 0) {
          setCodeError({
            name: "Please enter verification code",
            state: true,
          });
        } else {
          setCodeError({
            name: "",
            state: false,
          });
          const output = await axios.post(`${API_URL}auth/verifyOtp`, {
            otp: inputs.code,
            email: inputs.email,
          });

          if (output.status === 200 && output.data.status === 1) {
            const result = output.data.data[0];
            localStorage.setItem(
              "user",
              JSON.stringify({
                accessToken: result.accessToken,
                refreshToken: result.refreshToken,
              })
            );
   console.log("the result is ", result)
            localStorage.setItem(
              "user_id",
              result.user_type === "client"
                ? result?.client_id
                : result?.employee_id
            );
            localStorage.setItem("email", result.email);
            localStorage.setItem("first_name", result.first_name);
            localStorage.setItem("last_name", result.last_name);
            localStorage.setItem("user_role", result.user_type);
            if (result.user_type === "client") {
              localStorage.setItem("employee_id", result.employee_id);
              // localStorage.setItem("employee_id", result.employee);
              // localStorage.setItem("employee_id", result.employee_email);
              // localStorage.setItem("employee_id", result.employee_phone);


              // name: USER_DATA.employee || "-",
              // email: USER_DATA.employee_email || "-",
              // phone: USER_DATA.employee_phone || "",
              localStorage.setItem("user_root_folder", result?.s3_root_folder);
            }
            localStorage.setItem("USER_DATA", JSON.stringify(result));

            navigate(
              location.state?.from ? location.state.from : "/finlo/dashboard"
            );
          } else {
            Toaster.error(output.data.message);
          }
        }
      }
    } catch (error) {
      Toaster.error("Error occurred while login. Please try again");
    }
  };

  const googleAuth = async (google_email_user_id) => {
    const signUpResponse = await USER_ACTION.googleSignUp(google_email_user_id);
    setLoader(false);

    if (signUpResponse.status === 200 && signUpResponse.data.status === 1) {
      const result = signUpResponse.data.data[0];
      localStorage.setItem("user_id", result?.client_id);
      localStorage.setItem("email", result.email);
      localStorage.setItem("first_name", result.first_name);
      localStorage.setItem("last_name", result.last_name);
      localStorage.setItem("user_role", "client");
      localStorage.setItem("employee_id", result.employee_id);
      localStorage.setItem("user_root_folder", result.s3_root_folder);
      localStorage.setItem("USER_DATA", JSON.stringify(result));

      navigate("/finlo/dashboard");
    }
  };

  return (
    <div >
      <div className="login_container align_column" style={{backgroundColor: "rgb(211 235 255)"}}>
        <div className="login_block align_column" style={{backgroundColor: "white"}}>
          {error.state ? <div className="errormsg">{error.name}</div> : <></>}
          <div className="logo">
            <img src={"/resources/images/Finlotax.png"} alt="" />
          </div>
          <br/>
          <div className="signin_info">
           <h2 className="welcome">Welcome!</h2>
            <p className="SignUptext">Sign in</p>
          </div>
          <form action="">
            <div className="input_block">
              {emailError.state ? (
                <label htmlFor="email" className="error_text">
                  {emailError.name}
                </label>
              ) : (
                <label>Email Address</label>
              )}

              <input
                type="email"
                name="email"
                placeholder="bob@gmail.com"
                onChange={handleChange}
                className="input_section"
                disabled={verifyOTPState}
              />
            </div>
            
            {/* Password Input with Eye Icon Toggle */}
            <div className="input_block password-input-container">
            {passwordError.state ? (
                <label htmlFor="password" className="error_text">
                  {passwordError.name}
                </label>
              ) : (
                <label>Password</label>
              )}
              <div className="password-wrapper">
                <input
                  type={showPassword ? "text" : "password"}
                  name="password"
                  placeholder="Enter your password"
                  className="input_section password-input"
                  value={password}
                  onChange={handleChange}
                  disabled={verifyOTPState}
                />
                <span
                  className="password-toggle-icon"
                  onClick={togglePasswordVisibility}
                >
                  {showPassword ? <FaEyeSlash /> : <FaEye />}
                </span>
              </div>
            </div>
            <br/>
            <div className="input_block">
              <Link to="/forget_password" className="forgotpassword">
                forgot password ?
              </Link>
            </div>
         

            {verifyOTPState && (
              <>
                <div className="input_block">
                  {codeError.state ? (
                    <label htmlFor="text" className="error_text">
                      {codeError.name}
                    </label>
                  ) : (
                    <label>Verfication Code</label>
                  )}

                  <input
                    type="text"
                    name="code"
                    onChange={handleChange}
                    className="input_section"
                  />
                </div>
                <div className="otp-switch">
                  <div
                    className="otp-link"
                    onClick={(e) => handleSubmit(e, false)}
                  >
                    Resend OTP
                  </div>
                  <div
                    className="otp-link"
                    onClick={() => {
                      window.location.href = "/finlo/login";
                    }}
                  >
                    Back to login
                  </div>
                </div>
              </>
            )}
            <button
              className="signin_button"
              onClick={(e) => handleSubmit(e, verifyOTPState)}
            >
              {verifyOTPState ? "Verify OTP" : "Sign in"}
            </button>
          </form>
          <p className="alter_text">
            Don’t have account yet ?{" "}
            <Link to="/signup" className="signup_link" style={{color: "#1C74BC"}}>
              Sign up
            </Link>
          </p>
          <br></br>
          <p>
              <a href="https://finlotax.com/terms-and-conditions/" target="_blank" style={{color: "#1C74BC"}} >
               Terms of Use&nbsp;
              </a>
              <a href="https://finlotax.com/privacy-policy/" target="_blank" style={{color: "#1C74BC"}}>
               | Privacy Policy&nbsp;
              </a>
              <a href="https://finlotax.com/disclaimer/" target="_blank" style={{color: "#1C74BC"}}>
               | Disclaimer
              </a>
            </p>
          {/* <div className="google_login" onClick={google}>
            <img
              alt="google"
              src="https://developers-dot-devsite-v2-prod.appspot.com/identity/sign-in/g-normal.png"
              className="google_icon"
            />
            Sign in with Google
          </div> */}
        </div>
      </div>
      {isLoaded && <Spinner />}
    </div>
  );
};

export default LoginPage;
