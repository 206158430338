import API from "../../utils/api";
import { createAsyncThunk } from "@reduxjs/toolkit";

const insertClientReferral = async (params) => {
  try {
    const API_NAME = "referral/createReferral";
    const { data } = await API.post(`${API_NAME}`, params);
    return data.message;
  } catch (error) {
    return [];
  }
};

const getAllReferral = createAsyncThunk(
  "referral/getAllReferralStatus",
  async () => {
    try {
      const type = localStorage.getItem("user_role");
      const user_id = localStorage.getItem("user_id");
      const {
        data: { data },
      } = await API.get(
        `referral/getAllReferral${
          type === "client"
            ? "?client_id=" + user_id
            : type === "employee"
            ? "?employee_id=" + user_id
            : "?admin_id=" + user_id
        }`
      );
      return data;
    } catch (error) {
      console.log(error);
      return [];
    }
  }
);

export { insertClientReferral, getAllReferral };
