import React, { useEffect, useContext } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { Notification, Navbar } from "../index";
import { Copyright, ResetPassword } from "../../images";
import CustomButton from "../button";
import PowerSettingsNewIcon from "@material-ui/icons/PowerSettingsNew";
import "./layout.css";
import {
  CONTRACT_ACTION,
  INVOICE_ACTION,
  MASTER_ACTION,
} from "../../redux/action";
import { useDispatch } from "react-redux";
import { NotificationContext, Toaster } from "../../utils/common";
import { useMemo } from "react";

const Layout = () => {
  const { notifications, setNotifications } = useContext(NotificationContext);
  const USER_TYPE = localStorage.getItem("user_role");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    ["role", "source", "country", "role", "payment_mode", "activity"].forEach(
      (name) => {
        dispatch(MASTER_ACTION.getMasterData({ name }));
      }
    );
    dispatch(MASTER_ACTION.getSubActivityDetails());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const logout = () => {
    dispatch({ type: "resetReducers" });
    localStorage.clear();
    navigate("/");
  };

  const onUpdateNotification = async (notify_data) => {
    console.log("OnupdateNotification method is triggered", notify_data);
  
    // Update notification as read
    await MASTER_ACTION.updateNotification(notify_data.notification_id);
  
    // Refresh notifications
    const data = await MASTER_ACTION.getNotification();
    setNotifications(data);
  
    const ACTION = {
      folder: [null, "/finlo/documents"], // Removed DOCUMENT_ACTION
      document: [null, "/finlo/documents"], // Removed DOCUMENT_ACTION
      invoice: [
        INVOICE_ACTION.getInvoiceDetail,
        `${USER_TYPE === "client" ? "/finlo/payment" : "/finlo/invoice_contract"}`,
      ],
      contract: [
        CONTRACT_ACTION.getContractDetail,
        `${USER_TYPE === "client"
          ? "/finlo/contract_proposal"
          : "/finlo/invoice_contract"
        }`,
      ],
    };
  
    const { notification_type, notification_item_id } = notify_data;
  
    // Handle folder and document types without API calls
    if (["folder", "document"].includes(notification_type)) {
      navigate(ACTION[notification_type][1]);
      return;
    }
  
    // Handle other notification types
    const response = await ACTION[notification_type][0](notification_item_id);
    if (response.status === 200) {
      navigate(ACTION[notification_type][1], {
        state: {
          client_id: response.data.data.billed_to,
          mode: notification_type,
          data: response.data.data,
          view: true,
        },
      });
    } else {
      Toaster.error("Error occurred while directing to the path");
    }
  };
  

  const notificationsLength = useMemo(() => {
    // notifications.forEach((d, index) => {
    //   console.log(`Notification ${index + 1} view status:`, d.view_status);
    // });
  
  let count =  notifications.filter((d) =>   d.view_status === "Not Viewed").length;

    // console.log("the notifications count is",count) // returns the right value
    return count
      
  }, [notifications]);

  return (
    <div className="finlo-layout">
      <div className="side-nav">
        <Navbar />
      </div>
      <div className="body-container">
        <div className="finlo-appbar">
          <div className="login-user">{localStorage.getItem("first_name")}</div>
          <div className="user-avatar">
            {/* Dynamically display the first letter of the user's first name */}
            <div
          className="user-avatar avatar-circle"
          onClick={() => navigate("/finlo/profile")} // Navigate to profile
          title="Go to Profile"
        >
          {localStorage.getItem("first_name")?.charAt(0).toUpperCase() || "U"}
        </div>
            {/* <img
              src={sampleprofile}
              className="logout"
              alt=""
              onClick={() => { }}
            /> */}
          </div>
          <Notification
            badgeText={notificationsLength ? notificationsLength : ""}
            notifications={notifications}
            onMenuClick={onUpdateNotification}
          />
          <CustomButton
            ui="transparent"
            iconCls="change-password"
            icon={
              <img
                src={ResetPassword}
                // className="logout"
                alt=""
              />
            }
            handleClick={() => navigate("/finlo/change_password")}
          />
          <CustomButton
            ui="transparent"
            iconCls="logout-icon"
            icon={<PowerSettingsNewIcon fontSize="large" />}
            handleClick={logout}
          />
        </div>
        <main>
          <Outlet />
        </main>
        <div className="footer-wrapper">
          <img src={Copyright} alt="" />
          <div>{`FinloTax Inc, ${new Date().getFullYear()}  `}<span>&nbsp;&nbsp;&nbsp;Powered by <a href="https://www.trymool.ai" target="blank">
            MoolAI
          </a></span>    </div>

        </div>
      </div>
    </div>
  );
};

export default Layout;
