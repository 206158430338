import { useContext, useEffect, useMemo } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { NotificationContext } from "../utils/common";
import { getNotification } from "../redux/action/masterAction";
import Layout from "../components/layout";
import {
  AdminMenu,
  ClientMenu,
  EmployeeMenu,
  ManagerMenu,
} from "../components/navbar/contants";
import { Navigate } from "react-router-dom";

const PrivateRoute = () => {
  const routesRoles = useMemo(() => {
    const UserType = localStorage.getItem("user_role");
    const NonMenuItems = [
      { path: "/finlo/change_password" },
      { path: "/finlo/create_timesheet" },
      { path: "/finlo/notifications" },
    ];
    const NavMenu =
      UserType === "admin"
        ? [...AdminMenu, ...NonMenuItems]
        : UserType === "employee"
        ? [...EmployeeMenu, ...NonMenuItems]
        : UserType === "manager"
        ? [...ManagerMenu, ...NonMenuItems]
        : [...ClientMenu, ...NonMenuItems, { path: "/finlo/service_offers" }];
    return NavMenu.map((d) => d.path);
  }, []);

  const { setNotifications } = useContext(NotificationContext);
  let location = useLocation();

  useEffect(() => {
    if (localStorage.getItem("user_id")) {
      const fetch = async () => {
        const data = await getNotification();
        setNotifications(data);
      };
      fetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return localStorage.getItem("user_id") ? (
    <>
      {routesRoles.includes(location.pathname) ? (
        <Layout />
      ) : (
        <Navigate to={"/finlo/dashboard"} replace={true} />
      )}
    </>
  ) : (
    <Navigate
      to={"/login"}
      state={{ from: location.pathname }}
      replace={true}
    />
  );
};
export default PrivateRoute;
