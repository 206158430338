import React from "react";
import "./breadcrumbs.scss";

export default function BreadCrumbs(props) {
  const { navPath, handleClick } = props;
  return (
    <div className="breadcrumbs_wrapper">
      <nav className="breadcrumbs">
        {navPath?.map((item, index) => (
          <div
            key={`item-${index}`}
            className={`breadcrumbs__item ${
              index === navPath.length - 1 ? "is-active" : ""
            }`}
            onClick={() =>
              handleClick(item.name, item.id, item.created_by, index)
            }
          >
            {item.name}
          </div>
        ))}
      </nav>
    </div>
  );
}
