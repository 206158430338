import API from "../../utils/api";
import { createAsyncThunk } from "@reduxjs/toolkit";
import FileSaver from "file-saver";
import { Toaster } from "../../utils/common";

const getUserFiles = createAsyncThunk(
  "document/getUserDocsStatus",
  async (state) => {
    try {
      const {
        data: { data },
      } = await API.post(`document/getUserDocs`, state);
      return data;
    } catch (error) {
      return [];
    }
  }
);

const getUserFolders = createAsyncThunk(
  "folder/getUserFolderStatus",
  async (state) => {
    const { user_id, folder_name, folder_id } = state;
    try {
      const {
        data: { data },
      } = await API.get(
        `folder/getUserFolders/${user_id}/${folder_name}/${folder_id}`
      );
      return data;
    } catch (error) {
      console.log(error);
      return [];
    }
  }
);

const getFolderDetails = async (folder_id) => {
  try {
    const data = await API.get(`folder/getFolderDetails/${folder_id}`);
    return data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

const getDocumentDetails = async (document_id) => {
  try {
    const data = await API.get(`document/getDocumentDetails/${document_id}`);
    return data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

const uploadFile = async (state) => {
  const { formData } = state;

  try {
    const data = await API.post(`document/uploadfile`, formData);
    return data;
  } catch (error) {
    return [];
  }
};


const deleteFile = async (client_document_id, folder_name, file_name) => {
  //const { client_document_id, folder_name, file_name } = state;
  let headers = {
    user_type: localStorage.getItem("user_role") === "client" ? 2 : 1,
  };
  console.log("Inside Delete File: ",client_document_id,folder_name,file_name);
  
    headers = { ...headers, employee_id: localStorage.getItem("user_id") };

  try {
    const data = await API.get(
      `document/deletefile/${client_document_id}/${folder_name}/${file_name}`,
      {
        headers,
      }
    );
    console.log("DELETE RESPONSE", data);
    if (data.status === 200) {
      console.log("Document deleted successfully",data.status);
      Toaster.success("Document deleted successfully");
    }
    else
    {
      Toaster.error("Error occurred while deleting the document");
    }
    return data;
  } catch (error) {
    console.log("DELETE ERROR RESPONSE", error);
    Toaster.error("Error occurred while deleting the document");
    return [];
  }
};

const renameFile = async (formData) => {
  try {
    console.log("SENDING FORM DATA TO BE:", [...formData.entries()]);
    const response = await API.post(`document/renamefile`, formData, {
      headers: {
        "Content-Type": "multipart/form-data", // Ensure formData is recognized
      },
    });

    console.log("RENAME RESPONSE", response);
    if (response.status === 200) {
      Toaster.success("Document renamed successfully!");
    } else {
      Toaster.error("Error occurred while renaming the document.");
    }
    return response;
  } catch (error) {
    console.error("RENAME ERROR RESPONSE:", error);
    Toaster.error("Error occurred while renaming the document.");
    return [];
  }
};

const createFolder = async (state) => {
  let headers = {
    user_type: localStorage.getItem("user_role") === "client" ? 2 : 1,
  };
  if (localStorage.getItem("user_role") === "employee") {
    headers = { ...headers, employee_id: localStorage.getItem("user_id") };
  }
  try {
    const data = await API.post(`folder/createFolder`, state, {
      headers,
    });
    return data;
  } catch (error) {
    return [];
  }
};

const downloadViewFile = async (state) => {
  const { fileURL, document_name, document_type, type } = state;
  const data = await API({
    url: `document/viewfile`,
    method: "POST",
    data: { fileURL },
    responseType: "blob", // important
  });
  const RAW = data.data;
  if (type === "Preview") {
    return { ...data, raw: URL.createObjectURL(RAW) };
  } else {
    let blob = new Blob([RAW], {
      type: document_type,
    });
    FileSaver.saveAs(blob, document_name);
  }
};

const getBulkFiles = async (params) => {
  const data = await API({
    url: `document/bulkDownload`,
    method: "POST",
    data: params,
    responseType: "blob", // important
  });
  const RAW = data.data;

  let blob = new Blob([RAW], {
    type: "application/x-zip-compressed",
  });
  FileSaver.saveAs(blob, params.folder_name);
};
export {
  getUserFiles,
  getUserFolders,
  getFolderDetails,
  getDocumentDetails,
  uploadFile,
  deleteFile,
  createFolder,
  downloadViewFile,
  getBulkFiles,
  renameFile,
};
