import React, { useEffect, useLayoutEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Labelbox,
  Table,
  FrameBox,
  InfoTable,
  Button,
  Modal,
  ContractInvoiceView,
} from "../../components";
import { Grid, Paper, TableCell } from "@material-ui/core";
import "./payment.scss";
import { INVOICE_ACTION } from "../../redux/action";
import { dateFormatter } from "../../utils/common";
import { useLocation } from "react-router-dom";

// Import the QR Code PDF
import ZelleQR from "../../images/Finlotax Zelle Payment QR Code.pdf";
import { font } from "../../resources/fonts/fontBase64";

const ContractPayment = (props) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [invoices, setInvoices] = React.useState([]);
  const [InvoiceList, setInvoiceList] = React.useState([]);
  const [paymentType, setPaymentType] = React.useState("");  // Track selected payment method
  const [totalAmount, setTotalAmount] = React.useState(0);
  const [isModalOpen, setModalOpen] = React.useState({
    state: false,
    data: {},
  });
  const { userId } = props;

  const USER_DATA = userId
    ? props.USER_DATA
    : JSON.parse(localStorage.getItem("USER_DATA"));

    const [isButtonEnabled, setButtonEnabled] = React.useState(false); // State for enabling/disabling the button

  useMemo(() => {
    if (location.state?.data)
      setModalOpen({ state: true, data: location.state.data });
  }, [location]);

  useLayoutEffect(() => {
 let InvoiceList = dispatch(
 INVOICE_ACTION.getAllInvoices({
        billed_to: userId || localStorage.getItem("user_id"),
        status: 0,
      })
    );

  }, [userId, dispatch]);

  const {
    invoice: { allInvoices },
  } = useSelector((state) => state);

  useEffect(() => {

  // Helper function to extract the numerical part of the invoice number
  const extractInvoiceNumber = (invoice_no) => parseInt(invoice_no.replace('INV-', ''));

  // // Sorting by extracted numerical part of the invoice number in descending order
  const sortedInvoices = [...allInvoices].sort((a, b) => extractInvoiceNumber(b.invoice_no) - extractInvoiceNumber(a.invoice_no));
    // sortedInvoices.map((data) => {
    //   console.log(data.invoice_no);
    // });
    setInvoices(
      sortedInvoices.map((data) => {
        const { invoice_no, total, invoice_date } = data;
        return {
          select: false,
          invoice_no,
          invoice_date: dateFormatter(invoice_date),
          amount: `$ ${total}`,
        };
      })
    );
  }, [allInvoices]);

  const onChange = (value, key, rowIndex) => {
    invoices[rowIndex][key] = value;
    setInvoices([...invoices]);
  };

  useEffect(() => {
    const isAnyInvoiceSelected = invoices.some((invoice) => invoice.select);
    setButtonEnabled(isAnyInvoiceSelected);
  }, [invoices]);

  const total = useMemo(() => {
    return invoices.reduce(
      (accumulator, currentValue) =>
        accumulator +
        (currentValue.select
          ? Number(currentValue.amount.replace(/[^0-9.]/g, ""))
          : 0),
      0
    );
  }, [invoices]);
  // Zelle Payment Handler - Open Modal
  const handleZellePayment = () => {
    setModalOpen(true);
    setPaymentType("zelle");
    setTotalAmount(total);  // Capture the total amount at the time of payment
  };

  // SwipePay Handler - Redirect
  const handleSwipePay = () => {
    setPaymentType("swipepay");
    window.location.href = "https://www.swipepay.com/payment";  // Replace with actual SwipePay URL
  };
  const onActionClick = (action, row, actionIndex) => {
    setModalOpen({ state: true, data: allInvoices[actionIndex] });
  };

  const fields = [
    {
      type: "checkbox",
      changeData: onChange,
    },
    {
      type: "label",
    },
    {
      type: "label",
    },
    {
      type: "label",
    },
  ];
  return (
    <FrameBox title="Payment / Invoice">
      <Grid container spacing={3} className="paddingTop">
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <FrameBox title="Tax Associate Info" variant="small">
            <Paper>
              <InfoTable
                titles={["Name", "Email ID", "Phone"]}
                info={{
                  name: USER_DATA.employee || "-",
                  email: USER_DATA.employee_email || "-",
                  phone: USER_DATA.employee_phone || "",
                }}
              />
            </Paper>
          </FrameBox>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} className="support-info">
          <FrameBox title="Support Info" variant="small">
            <Paper>
              <InfoTable
                titles={["Email ID", "Phone"]}
                info={{
                  email: "help@finlotax.com",
                  contact: "(408) 822-9406",
                }}
              />
            </Paper>
          </FrameBox>
        </Grid>
        <Grid item xs={12}>
          <Table
            variant="small"
            title="Select the Invoice / Invoices you want to pay"
            header={[
              { id: "1", label: "" },
              { id: "2", label: "" },
              { id: "3", label: "Description / Invoice Ref. #" },
              { id: "4", label: "Invoice Date" },
              { id: "5", label: "Amount" },
            ]}
            data={invoices}
            fields={fields}
            icons={{ eyeIcon: true }}
            onActionClick={onActionClick}
            customRow={
              <>
                <TableCell></TableCell>
                <TableCell>Total</TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell>$ {total.toFixed(2)}</TableCell>
              </>
            }
          />
        </Grid>
        <Grid item xs={12}>
          <Labelbox
            type="checkbox"
            label={
            
              <span className="terms">
                I have read the{" "}
                <span>
                <a href="https://finlotax.com/privacy-policy/" target="_blank"  style={{textAlign:'center'}}>
                Terms & Conditions, Privacy Policy, Disclaimer
             </a>
              </span> and
                proceed to pay via following payment methods
              </span>
            }
            checked
          />
        </Grid>
        <Grid item xs={12}>
          <Paper className="payment-notes">
            <span className="payment-notes-msg">Notes:</span>
            <ul>
              <li>
                SwipePay will allow making payments via debit card/ credit
                card
              </li>
              <li>SwipePay will attract a convenience fee</li>
            </ul>
          </Paper>
        </Grid>
        <Grid item xs={12} className="flex flex-center">
          
        <Button
        disable={!isButtonEnabled} // Ensure button is disabled when no invoice is selected
            text={
              <div>
                <img
                  src={require("../../images/zelle-logo.png")}
                  alt="Zelle"
                  style={{ width: "30px", marginRight: "10px" }}
                />
                Pay with Zelle
              </div>
            }
            large
            handleClick={handleZellePayment}
          />
          
          {/* <Button text="SwipePay" large handleClick={handleSwipePay}/> */}
        </Grid>
      </Grid>
      {isModalOpen.state && (
        <Modal
          open={isModalOpen.state}
          title="Invoice Preview"
          maxWidth
          handleClose={() => setModalOpen({ state: false, data: {} })}
        >
          <ContractInvoiceView data={isModalOpen.data} label="Invoice" />
        </Modal>
      )}
       {/* Zelle QR Code Modal */}
       {isModalOpen && paymentType === "zelle" && (
        <Modal
          open={isModalOpen}
          title="Scan QR Code to Pay with Zelle"
          handleClose={() => setModalOpen(false)}
          maxWidth
        >
          <div className="qr-container" style={{fontSize: "18px", textAlign: "center"}}>
          <p style={{ margin: "10px 0"}}>Company Name: <strong>FinloTax Inc</strong></p>
          <p style={{ margin: "10px 0"}}>Zelle Id: <strong>accounts@finlotax.com</strong></p>
          <p style={{ margin: "10px 0"}}>Total Amount to be paid: <strong>${totalAmount.toFixed(2)}</strong></p>  {/* Display Total Amount */}
            <iframe
              src={ZelleQR}
              width="100%"
              height="600px"
              title="Zelle QR Code"
            />
           
          </div>
        </Modal>
      )}
    </FrameBox>
  );
};

export default ContractPayment;
