import React, { useState, useEffect, useMemo, useRef } from "react";
import {
  FileFolderCard,
  Labelbox,
  BreadCrumbs,
  Button,
  Modal,
  FrameBox,
} from "../../components";
import{Slider, Switch, FormControlLabel, Menu, MenuItem, IconButton, Dialog, TextField,  ListItemIcon,ListItemText} from '@mui/material';
import { useLocation } from "react-router-dom";
import { AddFolder, FileUpload } from "../../images";
import "./document.scss";
import { Toaster, dateTimeFormatter } from "../../utils/common";
import { useSelector, useDispatch } from "react-redux";
import { DOCUMENT_ACTION, CLIENT_ACTION } from "../../redux/action";
import Empty from "antd/es/empty";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Visibility, Edit, GetApp, Delete } from "@mui/icons-material";


const UploadDoc = () => {
  const location = useLocation();
  const [sendNotifications, setSendNotifications] = useState(false);
  const [notificationLevel] = useState(50);
  const [homeFolders, setHomeFolders] = useState([]);
  const [filetype, setFiletype] = useState("");
  const { userFiles, userFolders } = useSelector((state) => state.document);
  const clientData = useSelector((state) => state.client);
  const [renameModalOpen, setRenameModalOpen] = useState(false);
const [selectedFile, setSelectedFile] = useState(null);
const [newName, setNewName] = useState("");

  const clientDetails = location.state?.clientDetails;

  const dispatch = useDispatch();

  const getHomeFolderAndUserID = (type) => {
    // eslint-disable-next-line no-unused-expressions
    if (type === "id") {
      // eslint-disable-next-line no-unused-expressions
      return clientDetails?.client_id
        ? clientDetails?.client_id
        : localStorage.getItem("user_role") === "client" &&
            localStorage.getItem("user_id");
    }
    return clientDetails?.home_folder
      ? clientDetails?.home_folder
      : localStorage.getItem("user_role") === "client" &&
          localStorage.getItem("user_root_folder");
  };

  const [HOME_USER_ID, setHOME_USER_ID] = useState(
    getHomeFolderAndUserID("id")
  );

  const getNavPath = () => {
    // eslint-disable-next-line no-unused-expressions
    return clientDetails?.directory_details
      ? clientDetails?.client_document_id
        ? JSON.parse(clientDetails?.directory_details)
        : [
            ...JSON.parse(clientDetails?.directory_details),
            {
              id: clientDetails.client_folder_id,
              name: clientDetails.folder_name,
            },
          ]
      : [{ id: 0, name: "Home", created_by: 0 }];
  };

  const [HOME_FOLDER, setHOME_FOLDER] = useState(getHomeFolderAndUserID());

  const [navPath, setNavPath] = useState(getNavPath());
  const [currentIndex, setCurrentIndex] = useState(0);  // Track current breadcrumb index
  const prevIndexRef = useRef(0);  // Store previous index to detect direction
  const [isModalOpen, setModalOpen] = useState(false);
  const [docInfo, setDocInfo] = useState({ RAW_DATA: "", document_type: "" });
  const [newFolderName, setNewFolderName] = useState("");
  const [fileData, setFileData] = useState([]);
  const [folderInfo, setFolderInfo] = useState([]);
  const [fileStructure, setFileStructure] = useState({
    folders: [],
    files: [],
  });
  
  
  

let path='';
  useMemo(() => {
    setNavPath(getNavPath());
  }, [clientDetails]);

  const getPath = () => {
    const lastPath = navPath[navPath.length - 1];
    
    //console.log("GET PATH: ", lastPath);
  
    if (lastPath.name === "Home") {
      return { id: 0, name: HOME_FOLDER, created_by: "001" }; // Fallback for Home
    }
    return lastPath;
  };
  
  const getKeyPath = () => {
    let keyPathArr = [...navPath];
    keyPathArr.splice(0, 1, { id: 0, name: HOME_FOLDER });
    const keyPath = keyPathArr.map((u) => u.name).join("/");
    return keyPath;
  };

  const clientsList = useMemo(() => {
    return (
      localStorage.getItem("user_role") !== "client" &&
      clientData?.activeClients &&
      clientData.activeClients.map((data, index) => {
       // console.log("Client Data:",data);
        return {
          id: data.client_id,
          value: `${data.first_name} ${data.last_name} - ${data.email}`,
        };
      })
    );
  }, [clientData]);

  const chooseClient = async (e) => {
  
    const selectedClient = clientData.activeClients.find(
      (data) => data.client_id === e
    );
    setHOME_USER_ID(e);
    setNavPath([{ id: 0, name: "Home", created_by: 0 }]);
    setHOME_FOLDER(selectedClient.s3_root_folder);
  };
  /////************ API DATA PROCESS************////////////
  useEffect(() => {
    dispatch(CLIENT_ACTION.getActiveClients());
  }, [dispatch]);

  


  useEffect(() => {
    if (HOME_USER_ID) {
      setFileStructure((prev) => ({
        ...prev,
        files: userFiles,
      }));
    }
  }, [userFiles]);
 // Detect navigation direction
 useEffect(() => {
  if (currentIndex < prevIndexRef.current) {
    //console.log("Detected: Backward Navigation to Breadcrumb");
    const newPath = [{ id: 0, name: "Home", created_by: 0 }];
      setNavPath(newPath);
  
    
  } else if (currentIndex > prevIndexRef.current) {
    //console.log("Detected: Forward Navigation into Subfolder");
  }
  prevIndexRef.current = currentIndex;  // Store current index for future comparisons
}, [currentIndex]);

  useEffect(() => {
    if (HOME_USER_ID) {
      setFileStructure((prev) => ({
        ...prev,
        folders: userFolders,
      }));
    }
  }, [userFolders]);
 
  
  useEffect(() => {
    if (navPath.length > 0) {
      getFolderType();
    }
  }, [navPath, userFolders]);
 
  // useEffect(() => {
  //   console.log("Updated Path:", navPath);
  //   console.log("Current Index:", currentIndex);
  // }, [navPath, currentIndex]);

  // useEffect(() => {
  //   console.log("$$UPDATED HOME FOLDERS: ", homeFolders);
  // }, [homeFolders]);

  // Trigger API calls only when navPath changes
useEffect(() => {
  if (HOME_USER_ID) {
    getFoldersFiles();
  }
}, [navPath]);

// Centralize folder/files retrieval and type detection
const getFoldersFiles = async () => {
  //console.log("INSIDE getFoldersFiles HOME USER ID: ", HOME_USER_ID);
  let path = getPath();
  //console.log("INSIDE getFoldersFiles FILE PATH: ", path);

  try {
    const folderResponse = await dispatch(
      DOCUMENT_ACTION.getUserFolders({
        user_id: HOME_USER_ID,
        folder_name: path.name,
        folder_id: path.id,
        folder_type: path.folder_type,
      })
    );
    //console.log("DISPATCH RETURN VALUE (FOLDERS): ", folderResponse);

    if (folderResponse.payload) {
      setHomeFolders(folderResponse.payload);  // Update folders
    }
  } catch (error) {
    console.error("ERROR IN getUserFolders: ", error);
  }

  try {
    await dispatch(
      DOCUMENT_ACTION.getUserFiles({
        user_id: HOME_USER_ID,
        folder_name: path.name,
        folder_id: path.id,
        doc_type: path.doc_type,
      })
    );
  } catch (error) {
    console.error("ERROR IN getUserFiles: ", error);
  }
};

// Update folder type after folders are fetched
// useEffect(() => {
//   if (homeFolders.length > 0) {
//     getFolderType();
//   }
// }, [homeFolders]);

const getFolderType = () => {
  let currentPath = navPath[navPath.length - 1];
 // console.log("&& INSIDE FOLDER TYPE HOME FOLDER IS &&", homeFolders);
  const folderData = homeFolders.find(
    (data) => data.client_folder_id === currentPath.id
  );

  if (folderData) {
    //console.log("&& INSIDE FOLDER TYPE &&", folderData.folder_type);
    setFiletype(folderData.folder_type);  // Update state with folder type
  } else {
    if (currentPath.name === HOME_FOLDER) {
      //console.log("%% CURRENT PATH IS HOME FOLDER %%");
      setFiletype("home");  // Default for root
    } else {
      //console.log("UNDEFINED INSIDE GET FOLDER TYPE");
      setFiletype("");  // Clear if folder type unknown
    }
  }
};

// Handle breadcrumb navigation
const handleOpen = async (folder_name, id, created_by, index) => {
  let newPath;

  if (index !== undefined) {
    // Backward Navigation
    newPath = navPath.slice(0, index + 1);
    setCurrentIndex(index);
  } else {
    // Forward Navigation
    newPath = [...navPath, { id, name: folder_name, created_by }];
    setCurrentIndex(navPath.length);
  }

  setNavPath(newPath);
};

  // const clientFileUpload = async (formData, folderid) => {
    
  //   const USER_DATA = JSON.parse(localStorage.getItem("USER_DATA"));

  //   console.log("Client's Tax Advisor: ",USER_DATA);
  //   formData.append("path", HOME_FOLDER+"/To Associate");
  //   formData.append("home_folder", HOME_FOLDER);
  //   formData.append("folder_name", "To Associate");
  //   formData.append("folder_id", folderid);
  //   formData.append("user_id", HOME_USER_ID);
  //   formData.append("send_email", sendNotifications);
  //   formData.append("to_email", USER_DATA.employee_email);
  //   formData.append("from_email", "noreply@finlotax.com");
  //   formData.append("user_role", "client");
  //   formData.append("first_name", USER_DATA.employee);
    
    
  //      try {
  //         const response = await DOCUMENT_ACTION.uploadFile({formData,});
  //          dispatch(
  //             DOCUMENT_ACTION.getUserFiles({
  //             user_id: HOME_USER_ID,
  //             folder_name: "To Associate",
  //             folder_id: folderid,
  //             })
  //             );
  //             if (response.status === 200 && response.data.status === 1) {
  //                Toaster.success("Document upload successfully");
  //                setModalOpen(false);
  //               } else {
  //                  Toaster.error("Error occurred while uploading the document");
  //                 }
  //               } catch (error) {
  //                   Toaster.error("Error occurred while uploading the document");
  //               }
  //    }

  
  const uploadFileHandler = async (fileData, doc_type) => {

   //console.log("Inside Upload Send to client data: ",selectedClientData);
   
    
    let path = getPath();
    //console.log("File Path 1: ",path);
    let formData = new FormData();
    for (var i = 0; i <= fileData.length - 1; i++) {
      formData.append("file", fileData[i]);
    }
    //console.log("Path: ",path);
    //console.log("Send Email Notifications status:",sendNotifications);
  
    formData.append("path", getKeyPath());
    formData.append("home_folder", HOME_FOLDER);
    formData.append("directory_details", JSON.stringify(navPath));
    formData.append("folder_name", path.name);
    formData.append("folder_id", path.id);
    formData.append("doc_type", doc_type);
    formData.append("user_id", HOME_USER_ID);
    formData.append("send_email", sendNotifications);
    formData.append("to_email", selectedClientData["email"]);
    formData.append("from_email", "noreply@finlotax.com");
    formData.append("user_role", "employee");
    formData.append("first_name", selectedClientData["first_name"]);
    if(localStorage.getItem("user_role") === "client")
    {
      const USER_DATA = JSON.parse(localStorage.getItem("USER_DATA"));
      formData.delete("send_email");
      formData.delete("to_email");
      formData.delete("first_name");
      formData.delete("user_role");

      formData.append("send_email", true); // Replace newSendEmailValue with the desired value
      formData.append("to_email", USER_DATA.employee_email); 
      formData.append("first_name", USER_DATA.employee);  
      formData.append("user_role", "client"); 
    }
    try {
      const response = await DOCUMENT_ACTION.uploadFile({
        formData,
      });
      dispatch(
        DOCUMENT_ACTION.getUserFiles({
          user_id: HOME_USER_ID,
          folder_name: path.name,
          folder_id: path.id,
          doc_type: doc_type
        })
      );
      if (response.status === 200 && response.data.status === 1) {
        Toaster.success("Document Uploaded Successfully");
        setModalOpen(false);
      } else {
        Toaster.error("Error occurred while uploading the document");
      }
    } catch (error) {
      Toaster.error("Error occurred while uploading the document");
    }
  };

  const createFolderHandler = async (newFolderName, folder_type) => {
    //console.log("Key Path: ",getKeyPath());
    //console.log("Nav Path: ",JSON.stringify(navPath));
    let path = getPath();
    //console.log("Folder File Path: ",path);
           
            try {
              const response = await DOCUMENT_ACTION.createFolder({
                user_id: HOME_USER_ID,
                folderName: newFolderName,
                parentFolderName: path.name,
                parentFolderId: path.id,
                folder_type: folder_type,
                path: getKeyPath(),
                home_folder: HOME_FOLDER,
                directory_details: JSON.stringify(navPath),
              });
        
              dispatch(
        
                DOCUMENT_ACTION.getUserFolders({
                  user_id: HOME_USER_ID,
                  folder_name: path.name,
                  folder_id: path.id,
                  folder_type: folder_type
                })
              );
               
              if (response.status === 200 && response.data.status === 1) {
                Toaster.success("Folder created successfully");
                
                setModalOpen(false);
                setNewFolderName("");
               // Update navPath to navigate directly to the home folder of the selected client
      const newPath = [{ id: 0, name: "Home", created_by: 0 }];
      setNavPath(newPath);

      // Fetch updated folder and file structure
      await dispatch(
        DOCUMENT_ACTION.getUserFolders({
          user_id: HOME_USER_ID,
          folder_name: "Home",
          folder_id: 0,
          folder_type: folder_type,
        })
      );
      await dispatch(
        DOCUMENT_ACTION.getUserFiles({
          user_id: HOME_USER_ID,
          folder_name: "Home",
          folder_id: 0,
          doc_type: folder_type,
        })
      );
              } else {
                Toaster.error("Error occurred while creating the folder");
              }
          
            } catch (err) {
              Toaster.error("Error occurred while creating the folder");
            }
        };
    
        const handleDelete = async (docdata, folder_name, document_name) => {
          // Show confirmation dialog
          const isConfirmed = window.confirm(
            `Are you sure you want to delete the document: "${document_name}"?`
          );
        
          if (!isConfirmed) {
            //console.log("Delete action cancelled.");
            return; // Exit the function if user selects 'No'
          }
        
          let path = getPath();
          //console.log("File Path 2: ", path);
        
          try {
            await DOCUMENT_ACTION.deleteFile(
              docdata["client_document_id"],
              folder_name,
              document_name
            );
        
            // Re-fetch the updated files
            dispatch(
              DOCUMENT_ACTION.getUserFiles({
                user_id: HOME_USER_ID,
                folder_name: folder_name,
                folder_id: path.id,
              })
            );
        
           // console.log("Document deleted successfully.");
          } catch (error) {
            console.error("Error deleting item:", error);
          }
        };
        
        const handleRename = async (docdata, newName) => {
         // console.log("RENAME DOC DATA IS: ", docdata);
          //console.log("NEW NAME IS: ", newName);
        
          let formData = new FormData();
          let path = getPath();
          // Append all required data to FormData
          formData.append("client_document_id", docdata.client_document_id);
          formData.append("folder_name", docdata.folder_name);
          formData.append("document_name", docdata.document_name);
          formData.append("new_document_name", newName);
          formData.append("home_folder", HOME_FOLDER);
          formData.append("user_id", HOME_USER_ID);
          formData.append("client_folder_id", docdata.client_folder_id);
          formData.append("first_name", docdata.first_name);
          formData.append("last_name", docdata.last_name);
          formData.append("directory_details", docdata.directory_details);
          formData.append("document_type", docdata.document_type);
          formData.append("doc_type", docdata.doc_type);
          formData.append("document_extension", docdata.document_extension);
          formData.append("document_link", docdata.document_link);
          formData.append("document_size", docdata.document_size);
          formData.append("status", docdata.status);
        
          //console.log("FORM DATA CONTENT:", [...formData.entries()]); // Debug FormData content
        
          try {
            const response = await DOCUMENT_ACTION.renameFile(formData);
            // Re-fetch the updated files
            dispatch(
              DOCUMENT_ACTION.getUserFiles({
                folder_id: path.id,
                folder_name: formData.get("folder_name"),
                user_id: HOME_USER_ID,
              })
            );
            if (response.status === 200) {
              Toaster.success("Document renamed successfully!");
              setRenameModalOpen(false);
            } else {
              Toaster.error("Failed to rename the file/folder.");
              setRenameModalOpen(false);
            }
          } catch (error) {
            console.error("Error renaming file:", error);
            Toaster.error("Error occurred while renaming.");
          }
        };
        
const handleSwitchChange = (event) => {
  const isChecked = event.target.checked;
  setSendNotifications(isChecked);
  console.log("Switch toggled. Send Notifications:", isChecked);
 
};
  const viewDownloadFileHandler = async (
    type,
    { folder_name, document_name, document_type }
  ) => {
   // console.log("ACTION IS: ",type);
   // console.log("ACTION DATA: ",document_name, folder_name, document_type);
    
    
    if (type === "Delete") {
      //console.log("USER FOLDERS: ",userFolders);
      var docdata = userFiles.find(file => file.document_name === document_name);
    
     handleDelete(docdata,folder_name,document_name);
      
    }
    else
    {
      const response = await DOCUMENT_ACTION.downloadViewFile({
        fileURL: `${getKeyPath()}/${document_name}`,
        folder_name,
        document_name,
        document_type,
        type,

      });
      if (type === "Preview") {
        setDocInfo({ RAW_DATA: response.raw, document_type });
        setModalOpen("preview");
      }
      if (response["status"] !== 200) {
        Toaster.error("Error occurred while viewing the document");
      }
    }
    
  };

  const modelAction = () => {
    let folder_type = 'emp';
    let doc_type = 'emp';
    if (isModalOpen.includes("add_folder")) {
      //console.log("INSIDE ADD FOLDER");
      if (newFolderName.trim() === "") {
        Toaster.warning("Please enter folder name");
        return;
      }
      if(isModalOpen === "client_add_folder")
      {
       // console.log("INSIDE CLIENT ADD FOLDER");
        folder_type = 'client';
      }
      else if(isModalOpen === "office_add_folder")
      {
       // console.log("INSIDE OFFICE ADD FOLDER");
        folder_type = 'office';
      }
      createFolderHandler(newFolderName, folder_type);
    } 
    else if (isModalOpen.includes("_upload")) {
      //console.log("INSIDE DOC UPLOAD");
      if (fileData.length === 0) {
        Toaster.warning("Please upload document");
        return;
      }
      if(isModalOpen === "client_upload")
        {
          //console.log("INSIDE client DOC UPLOAD");
          doc_type = 'client';
        }
        else if(isModalOpen === "office_upload")
        {
         // console.log("INSIDE CLIENT DOC UPLOAD");
          doc_type = 'office';
        }
      uploadFileHandler(fileData, doc_type);
    }
  };
 
 
  const bulkDownloadHandler = async ({ folder_name, user_id, folder_id }) => {
    DOCUMENT_ACTION.getBulkFiles({
      user_id,
      folder_name,
      folder_id,
      path: getKeyPath(),
    });
  };
  var selectedClientData = "";
  const getAccess = (type) => {
      
      //  console.log("LOCAL STORAGE: ",localStorage);
      //  console.log("Folder and Files Data: ",fileStructure);
       selectedClientData = clientData.activeClients.find(client => client.client_id === HOME_USER_ID);
    
      if (selectedClientData !== undefined)
      {
    return (
      
        (type === "allclients" || type === "activeclient" && (selectedClientData.employee_id === localStorage.getItem("user_id") || selectedClientData.sales === localStorage.getItem("user_id")) 
        || (localStorage.getItem("user_role") !== "admin" &&
        localStorage.getItem("user_id") ===
          navPath[navPath.length - 1].created_by) ||
      localStorage.getItem("user_role") === "admin"  ) 
    );
  }
  };

  const FileActionMenu = ({ fileData }) => {
    const [anchorEl, setAnchorEl] = useState(null);
  
    const handleMenuClick = (event) => setAnchorEl(event.currentTarget);
    const handleMenuClose = () => setAnchorEl(null);
  
    const handleRenameClick = () => {
      
      setSelectedFile(fileData);
      setNewName(fileData.name); // Initialize with current name
      setRenameModalOpen(true);
      handleMenuClose();
     // console.log("RENAMED FILE: ",selectedFile);
    };
    return (
      <>
        {/* 3-dot menu */}
        <IconButton onClick={handleMenuClick}>
          <MoreVertIcon />
        </IconButton>
        <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
          <MenuItem onClick={() => viewDownloadFileHandler("Preview", fileData)}> <ListItemIcon>
          <Visibility fontSize="small" />
        </ListItemIcon>
        <ListItemText>Preview</ListItemText></MenuItem>
          <MenuItem onClick={() => viewDownloadFileHandler("Download", fileData)}><ListItemIcon>
          <GetApp fontSize="small" />
        </ListItemIcon>
        <ListItemText>Download</ListItemText></MenuItem>
          
          <MenuItem onClick={handleRenameClick}> <ListItemIcon>
          <Edit fontSize="small" />
        </ListItemIcon>
        <ListItemText>Rename</ListItemText></MenuItem>
          <MenuItem onClick={() => handleDelete(fileData, fileData.folder_name, fileData.document_name)}>
          <ListItemIcon>
          <Delete fontSize="small" color="error" />
        </ListItemIcon>
        <ListItemText>Delete</ListItemText>
          </MenuItem>
        </Menu>
      </>
    );
  };
  
  return (
    <>
    &nbsp;
    <div className="TitlePane">Documents</div>
    <BreadCrumbs
            navPath={navPath}
            handleClick={(folder_name, id, created_by, index) =>
              handleOpen(folder_name, id, created_by, index)
            }
          />
    <br/>

    <div className = "client-select-container" style={{position: "relative"}}>
    {localStorage.getItem("user_role") !== "client" && (
      <div className = "selectclient">
                <Labelbox
                  value={HOME_USER_ID}
                  type="select"
                  dropdown={clientsList}
                  labelname="Select Client"
                  changeData={(e) => chooseClient(e)}
                />
                    {getAccess("allclients") && selectedClientData!== undefined  && localStorage.getItem("user_role") !=="client" && (
                <div className="selected-client">&nbsp;{selectedClientData.first_name}&nbsp;{selectedClientData.last_name}&nbsp;- {selectedClientData.email}</div>
              )}
                </div>
              )}
              
              
              </div>
              <br/><br/>
              {(filetype === "client" || getKeyPath() === HOME_FOLDER) && (
      <FrameBox title="To Associate">
        
        {/* {localStorage.getItem("user_role") === "client" && getKeyPath() === HOME_FOLDER && (
      <div className="subheading">Upload tax-related documents or create folders only within the 'To Associate' folder. <br></br></div>)} */}
              <div>
                
                <div className="btn-wrapper">
                <div className="btn-wrapper-left">
                  
              {localStorage.getItem("user_role") === "client" && (getKeyPath() === HOME_FOLDER || getPath().created_by === HOME_USER_ID) &&(
                
                <Button
                className="clientaddfolder"
                  iconCls="btn_icon"
                  icon={<img src={AddFolder} alt="Add folder" />}
                  text="Add Folder"
                  handleClick={() => setModalOpen("client_add_folder")}
                />
              )}
            </div>
                {localStorage.getItem("user_role") === "client" && getKeyPath() !== HOME_FOLDER && getPath().created_by === HOME_USER_ID &&(
         <div>
            
              <Button
                className="clientupload"
                iconCls="btn_icon"
                icon={<img src={FileUpload} alt="File upload" />}
                text="Upload"
                handleClick={() => setModalOpen("client_upload")}
              />
              
              <br></br>
              </div>
              
            )}
            </div>
            <br/>
               {!!fileStructure.folders.length && 
  fileStructure.folders
    .filter((data) => data.folder_type === "client")
    .map((data) => (
      
      <FileFolderCard
      disable
      className = "clientfolder"
        key={data.client_folder_id} // Add a unique key for better React performance
        modifiedAt={dateTimeFormatter(data.updatedAt)}
        name={data.folder_name}
        details={data}
        type={"File Folder"}
        onMenuClick={(
          item,
          { folder_name, client_id, client_folder_id }
        ) => {
          bulkDownloadHandler({
            user_id: client_id,
            folder_name,
            folder_id: client_folder_id,
          });
        }}
        id={data.client_folder_id}
        options={["Download"]}
        onClick={(folder_name, id) =>
          handleOpen(folder_name, id, data.created_by)
        }
      />
    ))}
            {!!fileStructure.files.length && getKeyPath() !== HOME_FOLDER &&
              fileStructure.files.filter((data) => data.doc_type === "client")
              .map((data) => (
                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                <FileFolderCard
                  modifiedAt={dateTimeFormatter(data.updatedAt)}
                   className = "clientdoc"
                  document_type={data.document_type}
                  document_extension={data.document_extension}
                  size={`${Math.round(data.document_size / 1024)} kb`}
                  name={data.document_name}
                  type={"Document"}
                  details={data}
                  onMenuClick={viewDownloadFileHandler}
                  options={["Download"]}
                  onClick={() => viewDownloadFileHandler("Preview", data)}

                  
                />
                {/* <FileActionMenu fileData={data} /> */}
                </div>

              ))}
            {!fileStructure.files.length && !fileStructure.folders.length && (
              <Empty />
            )}
          </div>
             
      </FrameBox> )}
     <br/><br/>
     {(filetype === "emp" || getKeyPath() === HOME_FOLDER )&& (
     <FrameBox title="From Associate">
      
        <div>
          
          <div className="btn-wrapper">
            <div className="btn-wrapper-left">
              {(filetype === "emp" || getKeyPath() === HOME_FOLDER) && getAccess("activeclient") &&(
                <Button
                   className = "empaddfolder"
                  disable={!HOME_USER_ID}
                  iconCls="btn_icon"
                  icon={<img src={AddFolder} alt="Add folder" />}
                  text="Add Folder"
                  handleClick={() => setModalOpen("emp_add_folder")}
                />
              )}
            </div>
            <div>
            {filetype === "emp" && getAccess("activeclient") && getKeyPath() !== HOME_FOLDER  && localStorage.getItem("user_role") !== "client" && (
              <Button
               className = "empuploaddoc"
                 disable={!HOME_USER_ID}
                iconCls="btn_icon"
                icon={<img src={FileUpload} alt="File upload" />}
                text="Upload"
                handleClick={() => setModalOpen("emp_upload")}
              />
            )}
            </div>
          </div>
          <div>
          {!!fileStructure.folders.length &&
  fileStructure.folders
    .filter((data) => data.folder_type === "emp")
    .map((data) => (
      <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
      <FileFolderCard
       className = "empfolder"
        key={data.client_folder_id} // Add a unique key for better React performance
        modifiedAt={dateTimeFormatter(data.updatedAt)}
        name={data.folder_name}
        details={data}
        type={"File Folder"}
        onMenuClick={(
          item,
          { folder_name, client_id, client_folder_id }
        ) => {
          bulkDownloadHandler({
            user_id: client_id,
            folder_name,
            folder_id: client_folder_id,
           });
        }}
        id={data.client_folder_id}
        options={["Download"]}
        onClick={(folder_name, id) =>
          handleOpen(folder_name, id, data.created_by)
        }
      />
      </div>
    ))}
            {!!fileStructure.files.length  && getKeyPath() !== HOME_FOLDER &&
              fileStructure.files.filter((data) => data.doc_type === "emp")
              .map((data) => (
                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                <FileFolderCard
                 className = "empdoc"
                key={data.client_folder_id}
                  modifiedAt={dateTimeFormatter(data.updatedAt)}
                  document_type={data.document_type}
                  document_extension={data.document_extension}
                  size={`${Math.round(data.document_size / 1024)} kb`}
                  name={data.document_name}
                  type={"Document"}
                  details={data}
                  onMenuClick={viewDownloadFileHandler}
                  options={["Download"]}
                  onClick={() => viewDownloadFileHandler("Preview", data)}

                  
                />
                {localStorage.getItem("user_role") !== "client" &&
                 <FileActionMenu fileData={data} /> }
                 </div>
              ))}
            {!fileStructure.files.length && !fileStructure.folders.length && (
              <Empty />
            )}
          </div>
        </div>
      </FrameBox> )}
      <br/><br/>
      {localStorage.getItem("user_role") !== "client" && (filetype === "office" || getKeyPath() === HOME_FOLDER) &&  (
      <FrameBox title="Office Use Only">
        <div>
          
          <div className="btn-wrapper">
            <div className="btn-wrapper-left">
              {(filetype === "office" || getKeyPath() === HOME_FOLDER) && getAccess("activeclient") && (
                <Button
                 className = "officeaddfolder"
                  disable={!HOME_USER_ID}
                  iconCls="btn_icon"
                  icon={<img src={AddFolder} alt="Add folder" />}
                  text="Add Folder"
                  handleClick={() => setModalOpen("office_add_folder")}
                />
              )}
            </div>
            <div>
            {filetype === "office" && getAccess("activeclient") && getKeyPath() !== HOME_FOLDER  &&  (
              <Button
               className = "officedoc"
                 disable={!HOME_USER_ID}
                iconCls="btn_icon"
                icon={<img src={FileUpload} alt="File upload" />}
                text="Upload"
                handleClick={() => setModalOpen("office_upload")}
              />
            )}
            </div>
          </div>
          <div>
          {!!fileStructure.folders.length &&
  fileStructure.folders
    .filter((data) => data.folder_type === "office")
    .map((data) => (
     
      <FileFolderCard
       className = "officefolder"
        key={data.client_folder_id} // Add a unique key for better React performance
        modifiedAt={dateTimeFormatter(data.updatedAt)}
        name={data.folder_name}
        details={data}
        type={"File Folder"}
        onMenuClick={(
          item,
          { folder_name, client_id, client_folder_id }
        ) => {
          bulkDownloadHandler({
            user_id: client_id,
            folder_name,
            folder_id: client_folder_id,
           });
        }}
        id={data.client_folder_id}
        options={["Download"]}
        onClick={(folder_name, id) =>
          handleOpen(folder_name, id, data.created_by)
        }
      />
    ))}
            {!!fileStructure.files.length  && getKeyPath() !== HOME_FOLDER &&
              fileStructure.files.filter((data) => data.doc_type === "office")
              .map((data) => (
                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                <FileFolderCard
                 className = "officedoc"
                key={data.client_folder_id}
                  modifiedAt={dateTimeFormatter(data.updatedAt)}
                  document_type={data.document_type}
                  document_extension={data.document_extension}
                  size={`${Math.round(data.document_size / 1024)} kb`}
                  name={data.document_name}
                  type={"Document"}
                  details={data}
                  onMenuClick={viewDownloadFileHandler}
                  options={["Download"]}
                  onClick={() => viewDownloadFileHandler("Preview", data)}
                  
                />
                <FileActionMenu fileData={data} />
                </div>
              ))}
            {!fileStructure.files.length && !fileStructure.folders.length && (
              <Empty />
            )}
          </div>
        </div>
      </FrameBox> )}
      {isModalOpen && (
  <Modal
    title={
      isModalOpen.includes("upload")
        ? "Upload a file"
        : isModalOpen === "preview"
        ? "Preview"
        : "Add a new folder"
    }
    open={isModalOpen}
    handleClose={() => setModalOpen(false)}
    maxWidth={isModalOpen === "preview"}
  >

    <div className="add_folder_modal">
      {isModalOpen.includes("upload") ? (
        <>
          <Labelbox
            type="upload"
            changeData={(data) => setFileData(data)}
            value={fileData}
          />

          {/* Send Notifications Section */}
          {filetype === 'emp' &&  (
          <div style={{ marginTop: '10px' }}>
            <FormControlLabel
              control={
                <Switch 
                  checked={sendNotifications} 
                  onChange={handleSwitchChange} 
                  name="sendEmail" 
                />
              }
              label="Send Email Notifications"
            />
          </div>
          )}
        </>
      ) : isModalOpen !== "preview" ? (
        <Labelbox
          type="text"
          changeData={(data) =>
            setNewFolderName(data.replace(/[^\w ]/g, ""))
          }
          value={newFolderName}
          placeholder="Enter name"
        />
      ) : (
        // eslint-disable-next-line jsx-a11y/iframe-has-title
        <iframe
          src={docInfo.RAW_DATA}
          type={docInfo.document_type}
          style={{ width: "90vw", height: "80vh", overflow: "auto" }}
        />
      )}
      {isModalOpen !== "preview" && (
        <Button
          text={isModalOpen.includes("upload") ? "Upload" : "Add"}
          handleClick={() => modelAction({ 
            fileData, 
            newFolderName, 
            sendNotifications, 
            notificationLevel 
          })}
        />
      )}
    </div>
  </Modal>
)}

<Dialog open={renameModalOpen} onClose={() => setRenameModalOpen(false)}>
  <div style={{ padding: "16px" }}>
    {selectedFile !== null ?
    <h2>Rename {selectedFile.document_name}</h2> 
    : <h2>Rename File</h2> }
    <TextField
      label="New Name"
      value={newName}
      onChange={(e) => setNewName(e.target.value)}
      fullWidth
    />
    <div style={{ marginTop: "10px" }}>
    <Button
                className="renamebutton"
                  iconCls="btn_icon"
                  icon={<Edit fontSize="small" />}
                  text="Rename"
                  handleClick={() => handleRename(selectedFile, newName)}
                />
    </div>
  </div>
</Dialog>


    </>
  );
};

export default UploadDoc;
