/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable require-jsdoc */
import React from "react";
import * as FileSaver from "file-saver";
import { utils, write } from "xlsx";
import { ExcelIcon } from "../../images/index";

const Excel = ({ fileData, title }) => {
  const exportToCSV = () => {
    // const fileName = 'selectedFile';
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    // const ws = utils.json_to_sheet(csvData.data_product);
    // const wscols = [
    //   { wch: 10 }, // "characters"
    //   { wpx: 150 }, // "pixels"
    // ];

    const ws = utils.json_to_sheet([{}], {
      // header: ['Senat Report'],
    });

    utils.sheet_add_json(ws, fileData, {
      // origin: 2,
    });

    // ws['!cols'] = wscols;

    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = write(wb, {
      bookType: "xlsx",
      type: "array",
      cellStyles: true,
    });

    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, title + fileExtension);
  };

  return (
    <div className="icon-grid">
      <img src={ExcelIcon} alt="word" onClick={exportToCSV} />
    </div>
  );
};

export default Excel;
