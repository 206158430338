/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from "react";
import { NavLink, useNavigate } from "react-router-dom";
import "./navbar.scss";
import { AdminMenu, ClientMenu, EmployeeMenu, ManagerMenu } from "./contants";

const Navbar = () => {
  const navigate = useNavigate();
  const UserType = localStorage.getItem("user_role");
  const url = window.location.pathname;
  const NavMenu =
    UserType === "admin"
      ? AdminMenu
      : UserType === "employee"
      ? EmployeeMenu
      : UserType === "manager"
      ? ManagerMenu
      : ClientMenu;

  const handleNavClick = (menu, index) => {
    navigate(menu.path);
  };
  return (
    <div className={`finlo-navbar`}>
      <div className="finlo-logo">
        <center>
          <img src="/resources/images/Finlotax.png" alt="logo" />
        </center>
      </div>
      <ul>
        {NavMenu?.map((menu, index) => (
          <NavLink to={menu.path} key={menu.item}>
            <li
              className={`${url === "/finlo/" + menu.id ? "active" : ""}`}
              onClick={() => handleNavClick(menu, index)}
            >
              <img
                src={menu.icon}
                id={`${menu.id}-icon`}
                className={`${
                  UserType === "admin" ? "admin-nav-icon" : "client-nav-icon"
                }`}
                alt=""
              />
              <span id={`${menu.id}`}>{menu.item}</span>
            </li>
          </NavLink>
        ))}
      </ul>
    </div>
  );
};
export default Navbar;
