import {
  ManagePack,
  DashboardIcon,
  Notepad,
  Human,
  Book,
  Leader,
  ClientNav,
  Employees,
  Report,
  InvoiceNav,
  Timesheet,
} from "../../images";

//-------- FYR:: redirect path must contain menu id to active respective menu item ---------//

const ClientMenu = [
  {
    id: "dashboard",
    item: "Home",
    icon: DashboardIcon,
    path: "/finlo/dashboard",
  },
  { id: "profile", item: "Profile", icon: Human, path: "/finlo/profile" },
  {
    id: "documents",
    item: "Documents",
    icon: Book,
    path: "/finlo/documents",
  },
  {
    id: "contract_proposal",
    item: "Contract Proposal",
    icon: Notepad,
    path: "/finlo/contract_proposal",
  },
  {
    id: "payment",
    item: "Payments",
    icon: ManagePack,
    path: "/finlo/payment",
  },
  {
    id: "referral",
    item: "Referral Programs",
    icon: Leader,
    path: "/finlo/referral",
  },
];
const AdminMenu = [
  {
    id: "dashboard",
    item: "Home",
    icon: DashboardIcon,
    path: "/finlo/dashboard",
  },
  {
    id: "client",
    item: "Clients/Prospects",
    icon: ClientNav,
    path: "/finlo/client",
  },
  {
    id: "invoice_contract",
    item: "Invoice/Contract",
    icon: InvoiceNav,
    path: "/finlo/invoice_contract",
  },
  {
    id: "documents",
    item: "Documents",
    icon: Book,
    path: "/finlo/documents",
  },
  {
    id: "employees",
    item: "Employees",
    icon: Employees,
    path: "/finlo/employees",
  },
  { id: "reports", item: "Reports", icon: Report, path: "/finlo/reports" },
  {
    id: "referral",
    item: "Referral Programs",
    icon: Leader,
    path: "/finlo/referral",
  },
  {
    id: "timesheet_dashboard",
    item: "Timesheet",
    icon: Timesheet,
    path: "/finlo/timesheet_dashboard",
  },
  {
    id: "manage_client_role",
    item: "Manage Client Role",
    icon: ClientNav,
    path: "/finlo/manage_client_role",
  },
];
const ManagerMenu = [
  {
    id: "dashboard",
    item: "Home",
    icon: DashboardIcon,
    path: "/finlo/dashboard",
  },
  {
    id: "client",
    item: "Clients/Prospects",
    icon: ClientNav,
    path: "/finlo/client",
  },
  {
    id: "invoice_contract",
    item: "Invoice/Contract",
    icon: InvoiceNav,
    path: "/finlo/invoice_contract",
  },
  {
    id: "documents",
    item: "Documents",
    icon: Book,
    path: "/finlo/documents",
  },
  {
    id: "employees",
    item: "Employees",
    icon: Employees,
    path: "/finlo/employees",
  },
  { id: "reports", item: "Reports", icon: Report, path: "/finlo/reports" },
  {
    id: "referral",
    item: "Referral Programs",
    icon: Leader,
    path: "/finlo/referral",
  },
  {
    id: "timesheet_dashboard",
    item: "Timesheet",
    icon: Timesheet,
    path: "/finlo/timesheet_dashboard",
  },
];
const EmployeeMenu = [
  {
    id: "dashboard",
    item: "Home",
    icon: DashboardIcon,
    path: "/finlo/dashboard",
  },
  {
    id: "client",
    item: "Clients/Prospects",
    icon: ClientNav,
    path: "/finlo/client",
  },
  {
    id: "invoice_contract",
    item: "Invoice/Contract",
    icon: InvoiceNav,
    path: "/finlo/invoice_contract",
  },
  {
    id: "documents",
    item: "Documents",
    icon: Book,
    path: "/finlo/documents",
  },
  { id: "reports", item: "Reports", icon: Report, path: "/finlo/reports" },
  {
    id: "referral",
    item: "Referral Programs",
    icon: Leader,
    path: "/finlo/referral",
  },
  {
    id: "timesheet_dashboard",
    item: "Timesheet",
    icon: Timesheet,
    path: "/finlo/timesheet_dashboard",
  },
  {
    id: "manage_client_role",
    item: "Manage Client Role",
    icon: ClientNav,
    path: "/finlo/manage_client_role",
  },
];
export { AdminMenu, ClientMenu, EmployeeMenu, ManagerMenu };
