/* eslint-disable no-undef */
import React, {  useLayoutEffect, useMemo } from "react";
import { Labelbox, Table, Button } from "../../components";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Grid } from "@material-ui/core";
import { AddCircleOutlineOutlined } from "@material-ui/icons";
import "./timesheet.scss";
import {
  CLIENT_ACTION,
  EMPLOYEE_ACTION,
  MASTER_ACTION,
  TIMESHEET_ACTION,
} from "../../redux/action";
import { Toaster, dropdownName } from "../../utils/common";
import ValidationLibrary from "../../utils/validationfunction";
import moment from "moment";

const CreatePage = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const submitValidateRef = React.createRef();
  const cancelValidateRef = React.createRef();
  const viewModeSubActivity = React.useRef([]);
  const viewModeRef = React.useRef();
  const statusMode = React.useRef();

  const defaultValues = {
    start_date: {
      value: "",
      validation: [{ name: "required" }],
      error: null,
      errmsg: null,
      minDate: null,
      maxDate: null,
      customDateInput: "",
    },
    end_date: {
      value: "",
    },
    total_hours: {
      value: "00:00",
    },
    rejected_comments: {
      value: "",
    },
    itemDetails: [
      {
        date: "",
        client_id: "",
        business_name: "",
        activity_id: "",
        sub_activity_id: "",
        description: "",
        hours: "",
      },
    ],
  };

  const headers = [
    { id: "1", label: "Date" },
    { id: "2", label: "Client" },
    { id: "3", label: "Business Name" },
    { id: "4", label: "Activity" },
    { id: "5", label: "Sub Activity" },
    { id: "6", label: "Description" },
    { id: "7", label: "Hours" },
  ];

  const [fieldConfiguration, setFieldConfiguration] =
    React.useState(defaultValues);

  useLayoutEffect(() => {
    dispatch(EMPLOYEE_ACTION.getActiveEmployees());
    dispatch(CLIENT_ACTION.getActiveClients());
    if (location.state) {
      onChange("2023-03-13", "start_date", false, false, location.state);
    }
  }, []);

  const {
    client: { activeClients },
    master: { activity },
  } = useSelector((state) => state);

  const onItemAction = async (type, actionData, index) => {

    // for delete the row
    if (actionData.timesheet_item_id) {
      statusMode.current = {
        ...statusMode.current,
        currentDataItemRemoved: [
          ...statusMode.current.currentDataItemRemoved,
          actionData.timesheet_item_id,
        ],
      };
    }
    if (![undefined, null].includes(index))
      fieldConfiguration.itemDetails.splice(index, 1);
    // calculation();
    fieldConfiguration.total_hours.value = calculation();
    setFieldConfiguration((prevState) => ({ ...prevState }));
  };

  const calculation = (mutated_time, rowIndex) => {
    let totalSeconds = fieldConfiguration?.itemDetails?.reduce(
      (accumulator, currentValue, index) => {
        const time = index === rowIndex ? mutated_time : currentValue.hours;
        if (!time) {
          return accumulator;
        }
        const currentDuration = time.split(":");
        const hrs = parseInt(currentDuration[0], 10);
        const min = parseInt(currentDuration[1], 10);
        const currDurationSec = 60 * min + 60 * 60 * hrs;
        return (accumulator += currDurationSec);
      },
      0
    );
    const hours = Math.floor(totalSeconds / 3600);
    totalSeconds %= 3600;
    const minutes = Math.floor(totalSeconds / 60);
    return `${hours}:${minutes}`;
  };

  const onChange = async (value, key, rowIndex, inlineCallback, action) => {
  
    if (key === "start_date" || action) {
      var itemList = defaultValues.itemDetails;
      let startOfWeek;
      let endOfWeek;
      if (!action) {
         // Start of week is Sunday, end of week is Saturday
    startOfWeek = moment(value).startOf("week").format("YYYY-MM-DD");
    endOfWeek = moment(value)
          .startOf("week")
          .add("days", 6)
          .format("YYYY-MM-DD");
        // startOfWeek = moment(value).startOf("isoweek").format("YYYY-MM-DD");
        // endOfWeek = moment(value)
        //   .startOf("week")
        //   .add("days", 7)
        //   .format("YYYY-MM-DD");
      }
      const timesheetEntries = await TIMESHEET_ACTION.getTimesheetEntries(
        action
          ? { timesheet_id: action.timesheet_id }
          : {
              employee_id: localStorage.getItem("user_id"),
              start_date: moment(startOfWeek).format("YYYY-MM-DD"),
              end_date: moment(endOfWeek).format("YYYY-MM-DD"),
            }
      );
      if (action) {
        startOfWeek = moment(timesheetEntries[0].start_date).format(
          "YYYY-MM-DD"
        );
        endOfWeek = moment(timesheetEntries[0].end_date).format("YYYY-MM-DD");
      }
      fieldConfiguration.start_date.minDate = moment(startOfWeek).add("days", 1)
      .format("YYYY-MM-DD");
      fieldConfiguration.start_date.maxDate = moment(endOfWeek).add("days", 1)
      .format("YYYY-MM-DD");
      const errorcheck = ValidationLibrary.checkValidation(
        value,
        fieldConfiguration[key].validation
      );

      fieldConfiguration[key].customDateInput = `${moment(startOfWeek).format(
        "MMM DD yy"
      )} - ${moment(endOfWeek).format("MMM DD yy")}`;
      fieldConfiguration[key].value = startOfWeek;
      fieldConfiguration[key].error = !errorcheck.state;
      fieldConfiguration[key].errmsg = errorcheck.msg;
      fieldConfiguration.end_date.value = endOfWeek;
      setFieldConfiguration({ ...fieldConfiguration });

      if (
        timesheetEntries?.length > 0 &&
        [0, 1, 2, 3].includes(timesheetEntries[0].status)
      ) {
        fieldConfiguration.rejected_comments.value =
          timesheetEntries[0].status === 3
            ? timesheetEntries[0].rejected_comments
            : null;
        fieldConfiguration.total_hours.value = timesheetEntries[0].total_hours;
        viewModeRef.current = [1, 2].includes(timesheetEntries[0].status);
        statusMode.current = {
          timesheet_id: timesheetEntries[0].timesheet_id,
          currentData: timesheetEntries[0],
          currentDataItemRemoved: [],
          status: timesheetEntries[0].status,
        };
        const subActivites = await MASTER_ACTION.getSubActivity();
        // if (sub_activities instanceof Array) setSubActivity(sub_activities);
        itemList = timesheetEntries[0].itemDetails
          ?.sort((a, b) =>
            a.updatedAt < b.updatedAt ? 1 : b.updatedAt < a.updatedAt ? -1 : 0
          )
          .map((entry) => {
            const individualActivity = subActivites?.filter(
              (item) => item.sub_activity_id === entry.sub_activity_id
            );
            viewModeSubActivity.current = [
              ...viewModeSubActivity.current,
              individualActivity,
            ];
            return {
              date: new Date(entry.date),
              client_id: entry.client_id,
              business_name: entry.business_name,
              activity_id: entry.activity_id,
              sub_activity_id: entry.sub_activity_id,
              description: entry.description,
              hours: entry.hours,
              timesheet_item_id: entry.timesheet_item_id,
              status: entry.status,
            };
          });
      } else {
        fieldConfiguration.rejected_comments.value = null;
        fieldConfiguration.total_hours.value = null;
        viewModeRef.current = false;
        viewModeSubActivity.current = [];
        statusMode.current = {};
      }
      fieldConfiguration.itemDetails = itemList;
      setFieldConfiguration({ ...fieldConfiguration });
      return;
    }
     
    if (key === "date" && rowIndex !== undefined) {
    //  console.log("Handling date change:", { value, key, rowIndex });
      // const fieldValue = moment(value).format("YYYY-MM-DD");
      // console.log("the filed value is", fieldValue)
      fieldConfiguration.itemDetails[rowIndex][key] = value;
    
      setFieldConfiguration({ ...fieldConfiguration });
    //  console.log("the fieldconfigrations after set state-------", fieldConfiguration)
    }
  
    if (rowIndex || rowIndex === 0) {
  //    console.log("this onchange parent method is called",value, key, rowIndex, inlineCallback, action)
      const fieldValue = key === "hours" ? moment(value).format("HH:mm") : value;
      fieldConfiguration.itemDetails[rowIndex][key] = fieldValue;
      if (key === "client_id") {
        const clientData = activeClients.find(
          (data) => data.client_id === value
        );
        if (clientData instanceof Object) {
          fieldConfiguration.itemDetails[rowIndex].business_name =
            clientData.business_name;
        }
      }
      setFieldConfiguration({ ...fieldConfiguration });
      if (key === "hours") {
        fieldConfiguration.total_hours.value = calculation(
          fieldValue,
          rowIndex
        );
      }
    }
  };

  
  

  const clientsData = useMemo(() => {
    return activeClients.map((data) => {
      const { client_id, email } = data;
      return {
        id: client_id,
        value: dropdownName(data) + " - " + email,
      };
    });
  }, [activeClients]);

  const defaultFields = useMemo(() => {
    return [
      {
        type: "datepicker",
        validation: [{ name: "required" }],
        error: null,
        errmsg: null,
        changeData: onChange,
        disabled: viewModeRef.current || false,
        minDate: fieldConfiguration.start_date.minDate || null,
        maxDate: fieldConfiguration.start_date.maxDate || null,
      },
      {
        type: "select",
        validation: [{ name: "required" }],
        dropdown: clientsData,
        error: null,
        errmsg: null,
        changeData: onChange,
        disabled: viewModeRef.current || false,
      },
      {
        type: "text",
        // validation: [{ name: "required" }],
        value: "",
        error: null,
        errmsg: null,
        // disabled: true,
      },
      {
        type: "select",
        validation: [{ name: "required" }],
        error: null,
        errmsg: null,
        dropdown: activity,
        changeData: async (value, key, rowIndex) => {
          const sub_activities = await MASTER_ACTION.getSubActivity(value);
       //   console.log(defaultFields[4].dependentDropdown);
          if (sub_activities instanceof Array) {
            defaultFields[4].dependentDropdown[rowIndex] = sub_activities;
          } else Toaster.error(sub_activities);
          onChange(value, key, rowIndex);
        },
        disabled: viewModeRef.current || false,
      },
      {
        type: "select",
        validation: [{ name: "required" }],
        error: null,
        errmsg: null,
        changeData: onChange,
        dependentDropdown: viewModeSubActivity.current || [],
        disabled: viewModeRef.current || false,
        opt_id: "sub_activity_id",
        opt_value: "sub_activity",
      },
      {
        type: "text",
        // validation: [{ name: "required" }],
        value: "",
        error: null,
        errmsg: null,
        changeData: onChange,
        disabled: viewModeRef.current || false,
      },
      {
        type: "timeselector",
        validation: [{ name: "required" }],
        error: null,
        errmsg: null,
        changeData: (value, key, rowIndex) => {
          const inlineCallback = (error) => {
            defaultFields[1].validation[1].customValidate = error;
          };
          onChange(value, key, rowIndex, inlineCallback);
        },
        disabled: viewModeRef.current || false,
      },
      {
        type: "label",
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    clientsData,
    viewModeRef.current,
    viewModeSubActivity.current,
    activity,
    fieldConfiguration.start_date.minDate,
    fieldConfiguration.start_date.maxDate,
  ]);

  async function onSubmit(statusValue) {
    try {
      const mainvalue = {};
      const targetkeys = Object.keys(fieldConfiguration);
      for (const i in targetkeys) {
        if (targetkeys[i] === "itemDetails") {
          continue;
        }
        const errorcheck = ValidationLibrary.checkValidation(
          fieldConfiguration[targetkeys[i]].value,
          fieldConfiguration[targetkeys[i]].validation
        );
        fieldConfiguration[targetkeys[i]].error = !errorcheck.state;
        fieldConfiguration[targetkeys[i]].errmsg = errorcheck.msg;
        mainvalue[targetkeys[i]] = fieldConfiguration[targetkeys[i]].value;
      }
      const filtererr = targetkeys.filter(
        (obj) => fieldConfiguration[obj].error === true
      );
      if (filtererr.length > 0) {
        Toaster.error("Please fill up required fields");
      } else {
        const checkValidity = submitValidateRef.current;
        const filterError = checkValidity();
        if (filterError) {
          Toaster.error("Please fill up required fields");
        } else {
          let itemParams = [];
          if (statusMode.current?.currentDataItemRemoved?.length > 0) {
            const selectedData = statusMode.current?.currentData;
            itemParams = statusMode.current.currentDataItemRemoved.map(
              (_id) => {
                let {
                  date,
                  client_id,
                  business_name,
                  activity_id,
                  sub_activity_id,
                  hours,
                  description,
                } = selectedData.itemDetails.find(
                  (data) => data.timesheet_item_id === _id
                );
                return {
                  date,
                  client_id,
                  business_name,
                  activity_id,
                  sub_activity_id,
                  hours,
                  description,
                  timesheet_item_id: _id,
                  status: 0,
                };
              }
            );
            itemParams = [...fieldConfiguration.itemDetails, ...itemParams];
          } else {
            itemParams = fieldConfiguration.itemDetails;
          }
          const updateMode = [0, 3].includes(statusMode.current.status);
          const payload = {};
          const index = targetkeys.indexOf("itemDetails");
          if (index !== -1) {
            targetkeys.splice(index, 1);
          }
          targetkeys.forEach(
            (data) => (payload[data] = fieldConfiguration[data].value)
          );
          let payloadData = {
            ...payload,
            status: statusValue,
            total_hours: fieldConfiguration.total_hours.value,
            employee_id: localStorage.getItem("user_id"),
            itemDetails: itemParams,
          };

          const response = await TIMESHEET_ACTION.insertUpdateTimesheet({
            params: payloadData,
            insertUpdateFlag: updateMode ? 1 : 0,
            timesheet_id: statusMode.current.timesheet_id,
          });

          if (response.status === 200 && response.data.status === 1) {
            Toaster.success(
              `Timesheet ${
                updateMode
                  ? statusValue === 0
                    ? "saved"
                    : "submitted"
                  : "created"
              } successfully`
            );
            statusValue && handleCancel();
          } else if (response.status === 200 && response.data.status === 0) {
            Toaster.warning(response.data.message);
          } else {
            Toaster.error(
              `Error occurred while ${
                updateMode
                  ? statusValue === 0
                    ? "saving"
                    : "submitting"
                  : "creating"
              } the Timesheet`
            );
          }
        }
      }
      setFieldConfiguration((prevState) => ({
        ...prevState,
      }));
    } catch (err) {
      console.error(err.message);
    }
  }

  function handleCancel() {
    viewModeRef.current = false;
    viewModeSubActivity.current = [];
    statusMode.current = {};
    const cancel = cancelValidateRef.current;
    cancel();
    setFieldConfiguration(defaultValues);
  }

  return (
    <Grid container alignItems="center" spacing={2} className="grid-padding">
      <Grid item xs={12}>
        <Grid container spacing={2} className="paddingTop">
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            className="btn-wrapper-left"
          >
            <div className="TitlePane">Timesheet</div>
            <Button
              iconCls="btn_icon"
              icon={<AddCircleOutlineOutlined />}
              text="Add New"
              handleClick={handleCancel}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid container spacing={3} className="contract-padding">

        {/* ------CHOOSE DATE----------- */}
        <Grid item xs={12} sm={4} md={3} lg={3}>
          <Labelbox
            type="datepicker"
            labelname="Choose Date"
            changeData={(e) => onChange(e, "start_date")}
            value={fieldConfiguration.start_date.value}
            error={fieldConfiguration.start_date.error}
            errmsg={fieldConfiguration.start_date.errmsg}
            // minDate={fieldConfiguration.start_date.minDate || null}
            // maxDate={fieldConfiguration.start_date.maxDate || null}
            customDateInput={
              fieldConfiguration.start_date.customDateInput || null
            }
            // disableWeekends
          />
        </Grid>

        {/* ------TOTAL HOURS----------- */}
        <Grid item xs={12} sm={2} md={1} lg={1}>
          <Labelbox
            type="label"
            label="Total hours"
            value={fieldConfiguration.total_hours.value || "00:00"}
            classes="input-box-label"
          />
        </Grid>


        {fieldConfiguration.rejected_comments.value && (
          <Grid item xs={12} sm={6} md={4} lg={4}>
            <Labelbox
              type="textarea"
              labelname="Rejected Comment"
              value={fieldConfiguration.rejected_comments.value || ""}
              disabled
              rows={3}
            />
          </Grid>
        )}
      </Grid>

      <Grid container spacing={3} className="paddingTop" alignItems="center">
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Table
            variant="small"
            title="Add Items"
            header={
              !viewModeRef.current
                ? [...headers, { id: "9", label: "Action" }]
                : headers
            }
            data={fieldConfiguration.itemDetails}
            fields={defaultFields}
            hiddenFields={[
              "client_contract_item_id",
              "client_invoice_item_id",
              "status",
              "timesheet_item_id",
            ]}
            actions={!viewModeRef.current ? ["delete"] : null}
            // actionDefault
            onActionClick={onItemAction}
            actionElements={
              !viewModeRef.current ? (
                <Button
                  iconCls="btn_icon"
                  icon={<AddCircleOutlineOutlined />}
                  text="Add"
                  handleClick={() => {
              
                    fieldConfiguration.itemDetails.push({
                      date: "",
                      client_id: "",
                      business_name: "",
                      activity_id: "",
                      sub_activity_id: "",
                      description: "",
                      hours: "",
                      status: 1,
                    });
                    setFieldConfiguration({ ...fieldConfiguration });
                  }}
                />
              ) : null
            }
            validateRef={submitValidateRef}
            cancelValidate={cancelValidateRef}
          />
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <div className="hbox">
          {!viewModeRef.current && (
            <>
              <Button text="Save" large handleClick={() => onSubmit(0)} />
              <Button
                text={`${
                  statusMode.current?.status === 2 ? "Update" : "Submit"
                }`}
                large
                handleClick={() => onSubmit(1)}
              />
            </>
          )}
          {/* <Button text="Reset" large handleClick={handleCancel} /> */}
        </div>
      </Grid>
    </Grid>
  );
};

export default CreatePage;
