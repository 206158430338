import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Button } from "@material-ui/core";
import "./button.scss";

const useStyles = makeStyles({
  button: {
    minWidth: "unset",
    maxHeight: "2.5rem",
  },
  icon: {
    display: "inline-flex",
  },
  icon_right: {
    display: "flex",
    flexDirection: "row-reverse",
    alignItems: "center",
    columnGap: 10,
  },
  icon_left: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    columnGap: 10,
  },
  icon_top: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    rowGap: 10,
  },
  icon_bottom: {
    display: "flex",
    flexDirection: "column-reverse",
    alignItems: "center",
    rowGap: 10,
  },
  buttonRoot: (props) => ({
    backgroundColor: props.ui,
    "&:hover": {
      backgroundColor: props.ui,
    },
  }),
  // icolor: props => ({
  //     color: props.iconColor,
  //     '&:hover': {
  //         boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.1)'
  //    },
  //   }),
  font: {
    color: "white",
    fontSize: 14,
    textTransform: "capitalize",
    "& svg": {
      fontSize: 18,
    },
  },
  disabled: {
    background: "#F0F0F0",
  },
  large: {
    minWidth: "8rem",
  },
});

const CustomButton = React.forwardRef((props, ref) => {
  const classes = useStyles(props);
  const {
    text,
    ui = "primary",
    icon,
    iconAlign = "left",
    disable,
    handleClick,
    iconCls,
    large,
    buttonRef,
  } = props;

  return (
    <Button
      disabled={disable}
      className={`${ui || ""} ${classes.button} ${large ? classes.large : ""}`}
      onClick={handleClick}
      // disableRipple
      // disableElevation
      classes={{
        root:
          !ui.includes("primary", "secondary", "danger", "transparent") &&
          classes.CustombuttonRoot,
        disabled: classes.disabled,
      }}
      buttonRef={(el) => buttonRef && (buttonRef.current = el)}
    >
      <div
        className={
          `${classes.font} ${classes[`icon_${iconAlign}`]}` || classes.icon_left
        }
      >
        {icon && <i className={`${classes.icon} ${iconCls || ""}`}>{icon}</i>}
        {text && <div>{text}</div>}
      </div>
    </Button>
  );
});

export default CustomButton;
