import { createSlice } from "@reduxjs/toolkit";
import { getAllInvoices, getCollectionInvoices } from "../action/invoiceAction";

const initialState = {
  allInvoices: [],
  collectionInvoices: [],
};

export const invoiceSlice = createSlice({
  name: "invoice",
  initialState,
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder.addCase(getAllInvoices.fulfilled, (state, action) => {
      // Add user to the state array
      state.allInvoices = action.payload;
    });
    builder.addCase(getCollectionInvoices.fulfilled, (state, action) => {
      // Add user to the state array
      state.collectionInvoices = action.payload;
    });
  },
});

export default invoiceSlice.reducer;
